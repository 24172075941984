import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';


export default class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { plain, children } = this.props;

        return (
            <div className={classNames("cancel-footer", {"cancel-footer-plain": plain})}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-8">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Footer.propTypes = {
    plain: PropTypes.bool,
};

Footer.defaultProps = {
    plain: false
};
