import React, {Component, Fragment} from 'react';

import { Row, Col } from "reactstrap";
import Footer from "../util/footer";
import {crmrequest, get, post, get_select2_ajax} from "../crmapi";
import LoadingWrapper from "../util/loadingWrapper";
import ContentWrapper from "../util/contentWrapper";
import Fa from "../util/fa";
import InfiniteScroll from 'react-infinite-scroller';
import moment from "moment";
import { get_first_letter, lineBreaksHtml } from "../util/util";
import MultiAttachmentInput from "../util/multi_attachment_input"
import {deleteIn} from "immutable-setter";
import {Link} from "react-router-dom";
import classNames from 'classnames';


class MessageReplyBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            content: "",
            attachments: [],
            savingAttachment: false
        }
    }

    onMessageChange(e) {
        this.setState({content: e.target.value});
    }

    attachmentsChanged(attachments) {
        this.setState({attachments: attachments, savingAttachment: false});
    }


    removeAttachment(index, e) {
        e.preventDefault();
        this.setState({attachments: deleteIn(this.state.attachments, [index])});
    }

    onStartAttachmentUpload(attachments) {
        this.setState({savingAttachment: true});
    }

    uploadMessage() {
        let component = this;
        this.setState({loading: true});

        let data = {
            ticket: this.props.ticket.id,
            content: this.state.content,
            set_attachments: _.map(this.state.attachments, "id"),
        };

        post('messages/', data, function(response) {
            component.setState({loading: false, attachments: [], content: ""});
            component.props.onMessageAdded();
        });
    }

    render() {
        return (
            <div>
                <textarea
                    rows={4}
                    className="form-control mb-3"
                    value={this.state.content}
                    onChange={this.onMessageChange.bind(this)}
                    placeholder="Reply with your message here" />

                {this.state.attachments.length > 0 &&
                    <Fragment>
                        {_.map(this.state.attachments, ((attachment, index) =>
                            <div className="d-flex justify-content-between mt-2 mb-2" key={`attachment-${attachment.id}`}>
                                <span><Fa icon="paperclip" className="text-primary" /> &#160;&#160;<small>{attachment.filename}</small></span>
                                <a href="#" className="text-primary" onClick={this.removeAttachment.bind(this, index)}><Fa icon="times-circle" /></a>
                            </div>
                        ))}

                        <hr className="mr-0 ml-0 mt-2 mb-2" />
                    </Fragment>
                }

                <div className="d-flex justify-content-between mb-4">

                    <MultiAttachmentInput
                        className="btn btn-sm btn-outline-primary pl-3"
                        button_text="Add Attachment"
                        icon="paperclip"
                        onChange={this.attachmentsChanged.bind(this)}
                        attachments={this.state.attachments}
                        showAttachments={false}
                        onStartUpload={this.onStartAttachmentUpload.bind(this)} />

                    <button
                        className="btn btn-sm btn-primary pl-3 pr-3"
                        disabled={this.state.loading || this.state.savingAttachment || _.trim(this.state.content).length === 0}
                        onClick={this.uploadMessage.bind(this)} >
                        {this.state.loading ? <Fa icon="spinner" spin /> : <Fa icon="reply" />}&#160;&#160; Reply
                    </button>
                </div>
            </div>
        )
    }
}

class MessageAttachment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }
    }

    viewAttachment(e) {
        e.preventDefault();

        if(this.state.loading) {
            return;
        }

        this.setState({loading: true});
        let component = this;

        get(`messages/${this.props.message.id}/view_attachment/?attachment=${this.props.attachment.id}`, function(response) {
            component.setState({loading: false});

            if(response.data.url) {
                window.open(response.data.url, '_blank');
            } else {
                alert('No file found.')
            }
        });
    }

    render() {
        let attachment = this.props.attachment;

        return (
            <div>
                <a href="#" onClick={this.viewAttachment.bind(this)} className={classNames({"disabled": this.state.loading})}>
                    {this.state.loading ? <Fa icon="spinner" spin className="text-primary mr-2"/> : <Fa icon="paperclip" className="text-primary mr-2"/>}
                    <small>{attachment.filename}</small>
                </a>
            </div>
        );
    }

}


export class Message extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        }
    }

    expandMessage(e) {
        e.preventDefault();
        this.setState({expanded: true});

    }

    render() {
        let message = this.props.message;
        let content = message.content;
        let hasMore = false;

        if(!this.state.expanded) {
            content = _.truncate(message.content, {length: 115, separator: /[,!.;]? +/});
            hasMore = message.content.length > content.length;
        }

        return (
            <div className="card mb-3 message-card" key={`message-${message.id}`}>
                <div className="card-body">
                    <div className="message-header">
                        {message.from_object.avatar_image ? (
                            <img className="avatar" src={message.from_object.avatar_image} alt={`Profile image for ${message.from_object.name}`} />
                        ) : (
                            <span className="avatar avatar-blank">{get_first_letter(message.from_object.name)}</span>
                        )}

                        <div>
                            <strong className="name">{message.from_object.name}</strong>
                            <small className="date text-muted">{moment(message.date_sent).format('MMMM D')}</small>
                        </div>
                    </div>

                    <div>{lineBreaksHtml(content)}</div>

                    {message.attachments.length > 0 &&
                        <div className="mt-2">
                            {_.map(message.attachments, ((attachment, index) =>
                                <MessageAttachment attachment={attachment} key={`attachment-${attachment.id}`} message={message} />
                            ))}
                        </div>
                    }

                    {(hasMore || this.props.replyClicked) &&
                        <div className="d-flex justify-content-between mt-3">
                            <div>
                                {hasMore &&
                                    <a href="#" style={{textDecoration: "underline"}} onClick={this.expandMessage.bind(this)}>
                                        View full message
                                    </a>
                                }
                            </div>

                            {this.props.replyClicked &&
                                <button className="btn btn-sm btn-primary pl-3 pr-3" onClick={this.props.replyClicked}>
                                    <Fa icon="reply"/>&#160;&#160; Reply
                                </button>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}


export default class ServiceRequestMessages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ticket: null,
            messages: [],
            current_page: {},
            currentNumber: 0,
        }
    }

    loadTicket() {
        let component = this;
        let ticket_id = this.props.match.params.ticket_id;

        get(`tickets/${ticket_id}/`, function(response) {
            component.setState({ticket: response.data, loading: false});
        });
    }

    loadMoreMessages(page) {
        let component = this;

        get(`messages/?page=${page}&page_size=5&ticket=${this.state.ticket.id}`, function(response) {
            component.setState({current_page: response.data, messages: _.concat(component.state.messages, response.data.results)});
        });
    }

    componentDidMount() {
        this.loadTicket();
    }

    onMessageAdded() {
        this.setState({messages: [], current_page: {}, currentNumber: this.state.currentNumber + 1});
    }

    render() {
        let ticket = this.state.ticket;

        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <Link to={`/${this.props.match.params.ticket_id}`} className={"back-link"}>Back</Link>

                        <LoadingWrapper loading={this.state.loading}>{(() =>
                            <Fragment>
                                <h2 className="mb-0">Service Request {ticket.name}</h2>
                                <h1>Messages</h1>

                                <MessageReplyBox ticket={this.state.ticket} onMessageAdded={this.onMessageAdded.bind(this)}/>
                            </Fragment>
                        )}</LoadingWrapper>
                    </ContentWrapper>

                    {!this.state.loading &&
                        <Fragment>
                            <div className="gray-panel">
                                <ContentWrapper>
                                    <h2 className="mt-1 mb-4"><small><Fa icon="envelope"/></small> &#160;Messages</h2>

                                    {(!_.isEmpty(this.state.current_page) && this.state.messages.length === 0) &&
                                        <span className="text-muted">No messages found</span>
                                    }

                                    <InfiniteScroll
                                        key={`message-list-${this.state.currentNumber}`}
                                        loadMore={this.loadMoreMessages.bind(this)}
                                        hasMore={this.state.current_page.next !== null}
                                        loader={<div className="mt-3" key="loader"><Fa icon="spinner" spin /> loading messages</div>}
                                    >
                                        <div>
                                            {_.map(this.state.messages, (message) =>
                                                <Message message={message} key={`message-${message.id}`} /> )
                                            }
                                        </div>
                                    </InfiniteScroll>
                                </ContentWrapper>
                            </div>
                        </Fragment>
                    }
                </main>

                <Footer>
                    <Row>
                        <Col>Need assistance?</Col>
                        <Col className="text-right"><a href="/contact/">Contact Us</a></Col>
                    </Row>
                </Footer>
          </Fragment>
        );
    }
}
