import React, {Component} from 'react';
import axios from "axios/index";
import {Collapse} from 'reactstrap';
import _, {includes, filter, lowerCase} from 'lodash';
import ContentWrapper from "../util/contentWrapper";
import LoadingWrapper from "../util/loadingWrapper";


class FAQAccordionItem extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {open: false};
    }

    toggle() {
        this.setState({open: !this.state.open});
    }

    render() {
        const {item, i: index} = this.props;
        const {open: isOpen} = this.state;
        return (
            <div className={"acc-cont"}>
                <div className="handle d-flex justify-content-between" onClick={this.toggle}>
                    <div className="d-inline">
                        <h3>{item.question}</h3>
                    </div>
                    <div className="d-inline d-flex ">
                        <h3><i className={`fas fa-caret-down accordion-caret ${isOpen ? 'open' : ''}`} /></h3>
                    </div>
                </div>

                <Collapse {...{isOpen}}>
                    <div className="content" dangerouslySetInnerHTML={{__html: item.answer}}/>
                </Collapse>
            </div>
        );
    }
}


export default class FAQ extends Component {
    constructor(props) {
        super(props);
        this.renderFAQ = this.renderFAQ.bind(this);

        this.state = {
            loading: true,
            searchTerm: '',
            data: [],
        };
        this.updateSearchTerm = this.updateSearchTerm.bind(this);
    }

    componentDidMount() {
        axios('/api/faq/').then((response) => {
            this.setState({
                loading: false,
                data: response.data
            });
        });
    }

    updateSearchTerm(e) {
        this.setState({searchTerm: e.target.value})
    }

    renderFAQ() {
        let items = this.state.data.results;
        const searchTerm = lowerCase(this.state.searchTerm);
        if (searchTerm) {
            items = filter(
                items,
                item => {
                    // test whether the question and answer includes the search term
                    return includes(
                        // concatenate question and answer, and lowercase them
                        // pick > values gets an array with the values from question and answer
                        // join concatenates them with a ','
                        lowerCase(_(item).pick(['question', 'answer']).values().join()),
                        searchTerm
                    )
                }
            )
        }

        return (
            <div>
                {items.map(item => <FAQAccordionItem {...{item}} key={`faq-${item.id}`}/>)}
            </div>
        )
    }

    render() {
        return (
        <main>
            <ContentWrapper>
                <div className="mb-3">
                    <h1 className="mb-3">FAQ</h1>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="search-label"><i className="fas fa-search" /></span>
                        </div>
                        <input  type="text" className="form-control faq-search-input" placeholder="Search" aria-label="Search" aria-describedby="search-label" onChange={this.updateSearchTerm} />
                    </div>
                    {this.state.loading ? <i className="fas fa-spinner fa-spin"/> : this.renderFAQ()}
                </div>
            </ContentWrapper>
        </main>
        );
    }
}
