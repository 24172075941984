import React, {Component, Fragment} from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import WizardChoosePlan from './wizard_choose_plan';
import WizardChooseItem from './wizard_choose_item';
import DamageWizard from './damage_wizard';
import WizardPhotos from './wizard_photos';
import WizardMoreToAdd from './wizard_more_to_add';
import WizardReview from './wizard_review';
import WizardComplete from './wizard_complete';
import ItemWizard from './item_wizard';
import {get_select2_ajax, crmrequest, get} from "../crmapi";
import { addFormErrors } from "../autoform/util";
import ContentWrapper from "../util/contentWrapper";
import ServiceRequestList from "./service_request_list";
import ServiceRequestDetail from "./service_request_detail";
import ServiceRequestClaimDetail from "./service_request_claim_detail";
import ServiceRequestMessages from "./service_request_messages"


const START_URL = '/service-requests';


const STEPS = [
    {'component': WizardChoosePlan, route: '/:claim_id/select-plan'},
    {'component': WizardChooseItem, route: '/:claim_id/select-item'},
    {'component': ItemWizard, route: '/:claim_id/add-item', props: {newItem: true}},
    {'component': ItemWizard, route: '/:claim_id/edit-item', props: {newItem: false}},
    {'component': DamageWizard, route: '/:claim_id/damage/:index'},
    {'component': WizardPhotos, route: '/:claim_id/photos'},
    {'component': WizardMoreToAdd, route: '/:claim_id/more'},
    {'component': WizardReview, route: '/review'},
    {'component': WizardComplete, route: '/complete'},
];


export default class ServiceRequestRouter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            loading: true,
            current_step: 0,
            ticket: {},
            claim: {}
        };
    }

    downloadTicket(ticket_id) {
        let component = this;

        get(`tickets/${ticket_id}/`, function(response) {
            component.setState({ticket: response.data});
        });
    }

    saveData(data, formApi, onComplete, newClaim) {
        let thisComponent = this;
        this.setState({saving: true});

        let url = 'claims/';
        let method = 'POST';

        if(newClaim !== undefined && newClaim) {
            data.ticket = this.state.ticket.id;
        }else if(this.state.claim.id) {
            url = `claims/${this.state.claim.id}/`;
            method = 'PATCH';
        }

        crmrequest(method, url, function(response){
            thisComponent.updateClaimState(response.data, onComplete, newClaim);
        }, {data: data}).catch(function(error) {
            thisComponent.setState({saving: false});
            if(error.response.status === 400 && formApi !== undefined && formApi !== null) {
                addFormErrors(error.response.data, formApi);
            }
        });
    }

    updateClaimState(claimData, onComplete, newClaim=false) {
        let ticket = this.state.ticket;
        if(ticket.id) {
            if(newClaim) {
                ticket.claims.push(claimData);
            } else {
                let index = _.findIndex(ticket.claims, {'id': claimData.id});
                ticket.claims[index] = claimData;
            }
        }

        let state = {saving: false, ticket: ticket};

        if(newClaim || this.state.claim.id === undefined || this.state.claim.id === claimData.id) {
            state.claim = claimData;
        }

        this.setState(
            state,
            function() {
                if(onComplete !== undefined) {
                    onComplete();
                }
            }
        );
    }

    clearClaim(onComplete) {
        this.setState(
            {saving: false, claim: {}},
            function() {
                if(onComplete !== undefined) {
                    onComplete();
                }
            }
        );
    }

    goToNext(history, step) {
        history.push(this.getStepUrl(step + 1));
    }

    goToPrevious(history, step) {
        if(step === 3) { step = 2 } // if we are on the edit item step, we want to go back to select an item
        if(step === 4) { step = 2 } // if we are on the damage details step, we want to go back to select an item
        history.push(this.getStepUrl(step - 1));
    }

    getStepUrl(step) {
        let url = '';
        let route = STEPS[step].route;

        if(this.state.ticket.id) {
            url += `/${this.state.ticket.id}/edit`;
        } else if(this.state.claim.ticket) {
            url += `/${this.state.claim.ticket}/edit`;
        }

        if(_.includes(route, ':claim_id')) {
            let claim_id = this.state.claim.id;
            if(claim_id === undefined) {
                claim_id = this.state.ticket.claims[0].id;
            }
            route = _.replace(route, ':claim_id', claim_id)
        }

        if(_.includes(route, ':index')) {
            route = _.replace(route, ':index', 1)
        }

        return url + route;
    }

    renderLoading() {
        return <main>
            <ContentWrapper>
                <h2>Service Request</h2>
                <i className="fas fa-spinner fa-spin fa-lg"/>
            </ContentWrapper>
        </main>
    }

    renderRoute(history, step, index, match) {
        let component = this;

        if(!this.state.ticket.id && match.params.ticket_id) {
            setTimeout(function() {
                component.downloadTicket(match.params.ticket_id);
            }, 100);

            return this.renderLoading();
        }

        let claim = null;

        if(match.params.claim_id && this.state.claim.id !== parseInt(match.params.claim_id)) {
            claim = _.find(this.state.ticket.claims, ((claim) => claim.id === parseInt(match.params.claim_id)));
            if(claim === null) {
                document.location = '/404/';
                return null;
            }

            if(claim.status !== 'portal_draft') {
                document.location = `${START_URL}/${claim.ticket}/${claim.id}`;
                return null;
            }

            setTimeout(function() {
                component.setState({claim: claim});
            }, 100);

            return this.renderLoading();
        }

        let props = step.props || {};

        return React.createElement(
            step.component,
            {
                history: history, steps: STEPS, step: index,
                saving: this.state.saving, claim: this.state.claim, ticket: this.state.ticket, match: match,
                goToNext: this.goToNext.bind(this, history, index),
                goToPrevious: this.goToPrevious.bind(this, history, index),
                saveData: this.saveData.bind(this),
                updateClaimState: this.updateClaimState.bind(this),
                clearClaim: this.clearClaim.bind(this), ...props
            }
        );
    }

    render() {
        return (
            <Router basename={START_URL}>
                <Switch>
                    <Route component={ServiceRequestList} exact path='/' />

                    <Route
                        render={({ history, match }) => this.renderRoute(history, STEPS[0], 0, match)}
                        path='/create' exact />

                    <Route
                        render={({ history, match }) => this.renderRoute(history, STEPS[0], 0, match)}
                        path='/:ticket_id/create' exact />

                    <Route component={ServiceRequestDetail} exact path='/:ticket_id' />

                    {STEPS.map((step, index) =>
                        <Route
                            render={({ history, match }) => this.renderRoute(history, step, index, match)}
                            path={'/:ticket_id/edit' + step.route}
                            key={index} />
                    )}

                    <Route component={ServiceRequestMessages} exact path='/:ticket_id/messages'  />

                    <Route component={ServiceRequestClaimDetail} exact path='/:ticket_id/:claim_id'  />

                </Switch>
            </Router>
        );
    }
}
