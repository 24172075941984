import React, {Component, Fragment} from 'react';
import { Form } from 'informed';
import ContinueButton from "../util/continue_button";
import Footer from "../util/footer";
import  { Row, Col } from 'reactstrap';
import ContentWrapper from "../util/contentWrapper";
import MultiAttachmentInput from "../util/multi_attachment_input";
import { get } from "../crmapi";
import LoadingWrapper from "../util/loadingWrapper";
import classNames from 'classnames';
import {BasicCheckbox} from "../autoform/bootstrap";


export default class WizardPhotos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photos: [],
            receipts: [],
            loading: true,
            uploading: false,
            missing_photo: false,
            missing_receipt: false,
            no_receipt: this.props.claim.no_receipt,
        };
    }

    componentDidMount() {
        let component = this;
        get(`claims/${this.props.claim.id}/attachments/`, function(response) {
            component.setState({
                loading: false,
                photos: _.filter(response.data, ((x) => x.attachment_type === 'image')),
                receipts: _.filter(response.data, ((x) => x.attachment_type === 'receipt')),
            })
        })
    }

    onChange(type, attachments) {
        let state = {uploading: false};
        state[type] = attachments;

        if(type === 'photos' && attachments.length > 0) {
            state.missing_photo = false;
        }

        if(type === 'receipts' && attachments.length > 0) {
            state.missing_receipt = false;
        }

        this.setState(state);
    }

    onNoReceiptChange(e) {
        let state = {no_receipt: e.target.checked};
        if(e.target.checked) {
            state.missing_receipt = false;
        }

        this.setState(state);
    }

    onStartUpload() {
        this.setState({uploading: true});
    }

    saveAttachments() {
        let needs_photo = this.state.photos.length === 0;
        let needs_receipt = (!this.state.no_receipt && this.state.receipts.length === 0);

        if(needs_photo || needs_receipt) {
            if(needs_photo) {
                this.setState({missing_photo: true});
            }

            if(needs_receipt) {
                this.setState({missing_receipt: true});
            }

            return;
        }

        let component = this;
        let attachments = _.map(_.concat(this.state.photos, this.state.receipts), ((x) => x.id));
        this.props.saveData({set_attachments: attachments, no_receipt: this.state.no_receipt}, null, function() {
            component.props.goToNext();
        });
    }

    render() {
        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <h2 className="mb-0">Service Request</h2>
                        <h1>Item Damage</h1>

                        <LoadingWrapper loading={this.state.loading}>
                            <p className={classNames("large mb-1", {"text-danger": this.state.missing_photo})}>
                                Add one or more photos of the damage.
                            </p>

                            <p>Please attach a photo showing the entire damaged item and a photo showing the damage up close.</p>

                            <MultiAttachmentInput
                                button_text="Browse and Upload Photo"
                                attachment_type="image"
                                onChange={this.onChange.bind(this, 'photos')}
                                onStartUpload={this.onStartUpload.bind(this)}
                                attachments={this.state.photos} />

                            {this.state.missing_photo ? (
                                <small className="text-danger">At least one photo is required.</small>
                            ) : (
                                <small><i className="text-muted">Required</i></small>
                            )}

                            <br/><br/>

                            <p className={classNames("large mb-1", {"text-danger": this.state.missing_receipt})}>
                                Add a photo of your purchase receipt.
                            </p>

                            {this.state.receipts.length == 0 &&
                                <div className="form-group mb-2 mt-2">
                                    <div className="checkbox">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                onChange={this.onNoReceiptChange.bind(this)}
                                                className="custom-control-input form-check-input"
                                                id="no_receipt_checkbox"
                                                type="checkbox"
                                                checked={this.state.no_receipt}/>

                                            <label className="custom-control-label" htmlFor="no_receipt_checkbox">
                                                I do not have my receipt, please contact retailer on my behalf for a copy.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }

                            {!this.state.no_receipt &&
                                <Fragment>
                                    <MultiAttachmentInput
                                        button_text="Browse and Upload Photo"
                                        attachment_type="receipt"
                                        onChange={this.onChange.bind(this, 'receipts')}
                                        attachments={this.state.receipts} />

                                    {this.state.missing_receipt ? (
                                        <small className="text-danger">A receipt photo is required.</small>
                                    ) : (
                                        <small><i className="text-muted">Required</i></small>
                                    )}
                                </Fragment>
                            }

                        </LoadingWrapper>
                    </ContentWrapper>
                </main>

                {!this.state.loading &&
                    <Footer plain={true}>
                        <Row>
                            <Col xs={6}>
                                <button
                                    className="btn btn-outline-primary icon-btn-left btn-block"
                                    disabled={this.state.uploading}
                                    type="button"
                                    onClick={this.props.goToPrevious}>
                                    <i className="fas fa-arrow-left"/>
                                    Back
                                </button>
                            </Col>
                            <Col xs={6}>
                                <ContinueButton type="button" onClick={this.saveAttachments.bind(this)} loading={this.props.saving} disabled={this.state.uploading} />
                            </Col>
                        </Row>
                    </Footer>
                }
            </Fragment>
        );
    }
}

