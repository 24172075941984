import React, {Component, Fragment} from 'react';
import { Wizard, Steps, Step } from 'react-albus';
import { BootstrapText, BootstrapChoiceSelect, BootstrapCheckbox, BootstrapSelect2 } from "../autoform/bootstrap";
import { getValidators } from "../autoform/validators"
import { Form } from 'informed';
import { Row, Col } from 'reactstrap'
import ContinueButton from '../util/continue_button';
import axios from 'axios';
import {get_select2_ajax} from "../crmapi";
import Footer from "../util/footer";
import ContentWrapper from "../util/contentWrapper";


export default class FindPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plan_number: null,
            loading: false,
            errors: {},
            no_plan_found: false
        };
        this.formApi = null;
    }

    getFormApi(formApi) {
        this.formApi = formApi;
    }

    doFindPlan(next, isLast, data) {
        let component = this;

        this.setState(
            {loading: true},
            function () {
                axios.post('/api/users/find_plan/', data).then(function(response) {
                    component.setState({loading: false});

                    if(response.data.match_found) {
                        if(JSVARS.skip_set_password) {
                            document.location = JSVARS.next_url || '/';
                        } else {
                            document.location = '/users/finish-account/' + (JSVARS.next_url ? `?next=${JSVARS.next_url}` : '')
                        }
                    } else if(isLast) {
                        component.showNoPlan();
                    } else {
                        next();
                    }
                }).catch(function(error) {
                    if(error.response.status === 400) {
                        component.setState({loading: false});
                        for(let key in error.response.data) {
                            component.formApi.setError(key, error.response.data[key]);
                        }
                    }
                });
            }
        );
    }

    showForm() {
        this.setState({no_plan_found: false, loading: false})
    }

    showNoPlan() {
        this.setState({no_plan_found: true, loading: false})
    }

    renderForm() {
        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <h1>Find My Plan</h1>
                        <Wizard>
                            <Steps>
                                <Step id="plan_number" render={({ next }) => (
                                    <div>
                                        <Form onSubmit={this.doFindPlan.bind(this, next, false)}>
                                            {({ formState }) => (
                                                <div>
                                                    <p className="large">We weren't able to find your plan from your contact information.</p>

                                                    <BootstrapText
                                                        field="plan_number"
                                                        label="Do you have your plan ID number? If so, enter it here."
                                                        validate={getValidators(["required"])}
                                                        required={true}
                                                        labelClassName="title-label" />

                                                    {formState.values.plan_number ? (
                                                        <ContinueButton type="Submit" loading={this.state.loading}/>
                                                    ) : (
                                                        <button onClick={next} className="btn btn-outline-primary btn-block icon-btn">
                                                            I don't have my plan ID number
                                                            <i className="fas fa-arrow-right"/>
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </Form>
                                    </div>
                                )} />
                                <Step id="plan_receipt" render={({ next }) => (
                                    <div>
                                        <Form onSubmit={this.doFindPlan.bind(this, next, true)} getApi={this.getFormApi.bind(this)}>
                                            {({ formState }) => (
                                                <div>
                                                    <BootstrapSelect2
                                                        field="retailer"
                                                        label="At which retailer did you purchase your plan?"
                                                        validate={getValidators(["required"])}
                                                        required={true}
                                                        labelClassName="title-label"
                                                        ajax={get_select2_ajax('retailers/', 'name')}/>

                                                    <BootstrapText
                                                        field="receipt_number"
                                                        label="What is the receipt number for your plan purchase?"
                                                        validate={getValidators(["required"])}
                                                        required={true}
                                                        labelClassName="title-label" />

                                                    {(formState.values.receipt_number && formState.values.retailer) ? (
                                                        <ContinueButton type="Submit" loading={this.state.loading}/>
                                                    ) : (
                                                        <button type="button" className="btn btn-outline-primary btn-block icon-btn" onClick={this.showNoPlan.bind(this)}>
                                                            I don't have my receipt
                                                            <i className="fas fa-arrow-right"/>
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </Form>
                                    </div>
                                )} />
                            </Steps>
                        </Wizard>
                    </ContentWrapper>
                </main>

            </Fragment>
        );
    }

    renderNoPlan() {
        return <main>
            <ContentWrapper>
                <a href="/" className="back-link">Back to home</a>

                <h1>We weren't able to find a plan with your information.</h1>
                <p className="large">
                    Need help? If you purchased a Guardian plan please submit a copy
                    of your invoice to <a href="mailto:plan@onpointwarranty.com">plan@onpointwarranty.com</a>. If you do not have a copy of your invoice,
                    submit the information about your purchase (date of purchase, retailer name and city, and
                    your contact information: full name, phone number) to <a href="mailto:plan@onpointwarranty.com">plan@onpointwarranty.com</a>.
                </p>

                <a href="/contact" className="btn btn-primary icon-btn btn-block mb-4">
                    Contact Support <i className="fas fa-headset" />
                </a>

                <button type="button" className="btn btn-outline-primary icon-btn btn-block mb-4" onClick={this.showForm.bind(this)}>
                    Find Another Plan <i className="fas fa-search" />
                </button>

                <a href="/faq" className="btn btn-outline-primary icon-btn btn-block mb-4">
                    See FAQs <i className="fas fa-question" />
                </a>
            </ContentWrapper>
        </main>
    }

    render() {
        return this.state.no_plan_found ? this.renderNoPlan() : this.renderForm()
    }
}
