import React, {Component, Fragment} from 'react';
import { Form, Select, Option, Text } from 'informed';
import {BootstrapSelect2} from "../autoform/bootstrap";
import {getValidators} from "../autoform/validators";
import {get, get_select2_ajax, post} from "../crmapi";
import ContinueButton from "../util/continue_button";
import Footer from "../util/footer";
import ContentWrapper from "../util/contentWrapper";
import LoadingWrapper from "../util/loadingWrapper";
import {Scope} from "./damage_wizard";


export default class WizardChoosePlan extends Component {
    getFormApi(formApi) {
        this.formApi = formApi;
    }

    saveForm(data) {
        let component = this;
        this.props.saveData(data, this.formApi, function() {
            component.props.history.replace(`/${component.props.claim.ticket}/edit/${component.props.claim.id}/select-plan`);
            component.props.goToNext();
        }, this.props.match.params.claim_id === undefined);
    }

    submitForm() {
        if(this.formApi === undefined) {
            return;
        }

        this.formApi.submitForm();
    }

    render() {
        let plan_ajax = get_select2_ajax('plans/', 'plan_number');
        let claim = this.props.claim;

        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <h2 className="mb-0">Service Request</h2>
                        <h1>Find a Plan for Your Item</h1>
                        <Form onSubmit={this.saveForm.bind(this)} getApi={this.getFormApi.bind(this)} initialValues={this.props.claim}>
                            <BootstrapSelect2
                                placeholder="select plan..."
                                field="plan_id"
                                label="First, what plan provides coverage for your item? If you've already found and added the plan for this item, select it below."
                                validate={getValidators(["required"])}
                                labelClassName="title-label"
                                ajax={plan_ajax}
                                initial={claim.plan ? [claim.plan.id, claim.plan.plan_number] : null}/>
                        </Form>
                    </ContentWrapper>
                </main>

                <Footer plain={true}>
                    <ContinueButton loading={this.props.saving} onClick={this.submitForm.bind(this)}/>
                </Footer>
            </Fragment>
        );
    }
}

