import validator from 'validator';

export function isRequired(value) {
    if(value === true || value === false) { return value; }
    return !validator.isEmpty(_.toString(value), {ignore_whitespace: true});
}

export function maxLength(value, length) {
    return validator.isLength(value, {max: length});
}

const validators = {
    email: {func: validator.isEmail, message: "Enter a valid email address."},
    required: {func: isRequired, message: "This field is required."},
    maxLength: {func: maxLength, message: function (length) { return `This field must not contain more than ${length} characters.`}}
};

export function getValidators(options) {
    return function(value) {
        for(let index in options) {
            if(options.hasOwnProperty(index)) {
                let validator_name = options[index];
                let validator_option = undefined;

                if(_.isArray(validator_name)) {
                    validator_name = validator_name[0];
                    validator_option = validator_name[1]
                }

                let validator_info = validators[validator_name];
                value = value === undefined ? "" : value;
                let result = validator_option === undefined ? validator_info.func(value) : validator_info.func(value, validator_option);

                if (!result) {
                    if(_.isFunction(validator_info.message)) {
                        return validator_info.message(validator_option)
                    }

                    return validator_info.message;
                }
            }
        }
    }
}
