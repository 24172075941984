import React, {Component, Fragment} from 'react';

import classNames from 'classnames';
import { Row, Col, Button } from 'reactstrap';
import { joinedListLimit } from '../util/util'

export default class ServiceRequestListItem extends Component {
    renderDetails() {
        let ticket = this.props.ticket;

        if(ticket.claims.length > 0) {
            return <div>
                <div>{this.getPlansString()}</div>
                <div>{this.getItemsString()}</div>
            </div>
        } else {
            return <i className="text-muted">No claims found</i>
        }
    }

    getPlansString() {
        return joinedListLimit(
            _(this.props.ticket.claims)
                .filter(((claim) => claim.plan !== null))
                .map(((claim) => `Plan #${claim.plan.plan_number}`))
                .uniq()
                .value()
        );
    }

    getItemsString() {
        return joinedListLimit(
            _(this.props.ticket.claims)
                .filter(((claim) => claim.item !== null))
                .map(((claim) => claim.item))
                .uniqBy('id')
                .map((item) => item.user_description || item.description)
                .value()
        );
    }

    onView() {
        this.props.history.push(`/${this.props.ticket.id}`);
    }

    render() {
        let ticket = this.props.ticket;

        return (
            <div className={classNames("card", {"mb-3": !this.props.last})}>
                <div className="card-body">
                    <Row>
                        <Col xs={8}>
                            <h5 style={{fontSize: 20}}>Service Request {ticket.name}</h5>
                            {this.renderDetails()}
                        </Col>
                        <Col xs={4} className="text-right">
                            <Button color="primary" size="sm" style={{minWidth: 80}} onClick={this.onView.bind(this)}>View</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
