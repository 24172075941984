import React, { Component } from 'react';
import PropTypes from "prop-types";


export default class LoadingWrapper extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { loading } = this.props;

        if(loading) {
            return (
                <div>
                    <i className="fas fa-spinner fa-spin"/>
                </div>
            );
        }

        if(_.isFunction(this.props.children)) {
            return this.props.children();
        }

        if(_.isUndefined(this.props.children)) {
            return null;
        }

        return this.props.children;
    }
}

LoadingWrapper.propTypes = {
    loading: PropTypes.bool,
};
