import React, { Component } from 'react'
import {BasicSelect, Option, asField} from 'informed';


export class ChoiceBasicSelect extends Component {
    render () {
        let { choices, allowBlank, ...rest } = this.props;

        return <BasicSelect {...rest}>
            {allowBlank &&
                <Option value="">--------</Option>
            }

            {choices.map(({name, display_name}) =>
                <Option value={name} key={name}>{display_name}</Option>
            )}

        </BasicSelect>
    }
}

ChoiceBasicSelect.defaultProps = {
    allowBlank: true,
};
