import React, {Component, Fragment} from 'react';
import { Wizard, Steps, Step } from 'react-albus';
import { BootstrapText, BootstrapChoiceSelect, BootstrapCheckbox, BootstrapSelect2 } from "../autoform/bootstrap";
import { getValidators } from "../autoform/validators"
import { Form } from 'informed';
import { Row, Col } from 'reactstrap'
import ContinueButton from '../util/continue_button';
import axios from 'axios';
import Footer from "../util/footer";
import ContentWrapper from "../util/contentWrapper";


export default class UserRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: false,
            errors: {}
        };
        this.formApi = null;
    }

    getFormApi(formApi) {
        this.formApi = formApi;
    }

    saveStepOne(next, data) {
        next();
        this.setState({data: data});
    }

    saveStepTwo(next, data) {
        let component = this;
        this.setState(
            {data: _.assign(this.state.data, data), loading: true},
            function () {
                axios.post('/api/users/register/', this.state.data).then(function(response) {
                    if(response.data.match_found) {
                        if(JSVARS.skip_set_password) {
                            document.location = JSVARS.next_url || '/';
                        } else {
                            document.location = '/users/finish-account/' + (JSVARS.next_url ? `?next=${JSVARS.next_url}` : '')
                        }
                    } else {
                        document.location = '/plans/find-plan/?next=' + JSVARS.next_url
                    }
                }).catch(function(error) {
                    if(error.response.status === 400) {
                        component.setState({loading: false});
                        for(let key in error.response.data) {
                            component.formApi.setError(key, <div dangerouslySetInnerHTML={{__html: error.response.data[key]}}/>);
                        }
                    }
                });
            }
        );
    }

    render() {
        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <h1>About You</h1>
                        <Wizard>
                            <Steps>
                                <Step id="name" render={({ next }) => (
                                    <div>
                                        <Form onSubmit={this.saveStepOne.bind(this, next)}>
                                            <BootstrapText
                                                field="name"
                                                label="What's your name (first and last)?"
                                                validate={getValidators(["required"])}
                                                required={true}
                                                labelClassName="title-label"
                                                autoFocus={true} />

                                            <ContinueButton type="Submit" />
                                        </Form>
                                        <hr/>
                                        <p className="large">Already have an account?</p>
                                        <a href="/users/login/" className="btn btn-outline-primary btn-block">Log In</a>
                                    </div>
                                )} />
                                <Step id="other" render={({ next }) => (
                                    <div>
                                        <p className="large">How can we best contact you?</p>
                                        <Form onSubmit={this.saveStepTwo.bind(this, next)} getApi={this.getFormApi.bind(this)}>
                                            <BootstrapText field="email" label="Primary Email" validate={getValidators(["required"])} required={true} autoFocus="true" />

                                            <BootstrapText field="zip_code" label="Zip Code" validate={getValidators(["required"])} required={true} />

                                            <Row>
                                                <Col>
                                                    <BootstrapText field="phone" label="Primary Phone" validate={getValidators(["required"])} required={true} />
                                                </Col>
                                                <Col>
                                                    <BootstrapSelect2
                                                        field="phone_type"
                                                        label="&#160;"
                                                        allowBlank={false}
                                                        data={[{id: "mobile", name: "Mobile"}, {id: "home", name: "Home"}, {id: "work", name: "Work"}]}
                                                        initialValue={"mobile"}
                                                    />
                                                </Col>
                                            </Row>

                                            <BootstrapCheckbox
                                                field="agree"
                                                label="By providing my email, I agree to be contacted by Guardian Protection Products to receive information about my plans, service requests, and updates."
                                                labelClassName="custom-control-label-light"
                                                validate={getValidators(["required"])}
                                            />

                                            <ContinueButton type="Submit" loading={this.state.loading}/>
                                        </Form>
                                    </div>
                                )} />
                            </Steps>
                        </Wizard>
                    </ContentWrapper>
                </main>

                <Footer>
                    <a href="/">Cancel</a>
                </Footer>
            </Fragment>
        );
    }
}
