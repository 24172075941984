import React, {Component, Fragment} from 'react';
import { Form } from 'informed';
import Footer from "../util/footer";
import ContentWrapper from "../util/contentWrapper";
import Fa from '../util/fa';


export default class WizardComplete extends Component {
    render() {
        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <h1>
                            <div className="icon-circle icon-circle-large float-right mt-3 ml-1"><Fa icon="check"/></div>
                            We've received your service request.
                        </h1>

                        <p className="large mt-4">
                            Thank you for your submission and for being a valued customer.
                            Your claim has been submitted for further review. Please allow 10 business days to provide you a response.
                        </p>
                    </ContentWrapper>
                </main>

                <Footer plain={true}>
                    <a className="btn btn-outline-primary btn-block icon-btn" href="/">
                        Return home
                        <Fa icon="home" />
                    </a>
                </Footer>
            </Fragment>
        );
    }
}

