import React from 'react';
import PropTypes from 'prop-types';
import Fa from './fa';
import classNames from 'classnames';
import { post } from '../crmapi';


export default class MultiAttachmentInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelectFile = this.handleSelectFile.bind(this);

        this.state = {
            loading: false,
            attachments: this.props.attachments,
        };
    }

    handleSelectFile(event) {
        const { onChange, onStartUpload } = this.props;
        let component = this;

        if(onStartUpload) {
            onStartUpload();
        }

        this.setState({loading: true});

        let formData = new FormData();
        formData.append('attachment', event.target.files[0]);
        if(this.props.attachment_type != null) {
            formData.append('attachment_type', this.props.attachment_type);
        }

        post('attachments/', formData, (response) => {
            component.refs.file_input.value = "";
            $(component.refs.file_input).change();

            component.setState({
                loading: false,
                attachments: component.state.attachments.concat([response.data])
            }, function(){
                if (onChange) {
                    onChange(component.state.attachments);
                }
            });
        });

    }

    handleDeleteFile(id, event) {
        event.preventDefault();

        if(this.state.loading) {
            return;
        }

        const { onChange } = this.props;

        this.setState({
            'attachments': _.filter(this.state.attachments, function(x) { return x.id !== id; })
        }, function(){
            if (onChange) {
                onChange(this.state.attachments);
            }
        });
    }

    canUpload() {
        return !this.state.loading && (this.props.max_number < 0 || this.state.attachments.length < this.props.max_number);
    }

    render() {
        return (
            <div className="multi-attachment">
                <div className="file-upload-input">
                    <div className={classNames(this.props.className, "icon-btn", {"disabled": !this.canUpload()})}>
                        <span>{this.props.button_text}</span>
                        <input type="file" ref="file_input" onChange={this.handleSelectFile} disabled={!this.canUpload()} />
                        {this.state.loading ? (
                            <Fa icon="spinner" spin />
                        ) : (
                            <Fa icon={this.props.icon} />
                        )}
                    </div>
                </div>

                {this.props.showAttachments &&
                    this.state.attachments.map((attachment, index) =>
                        <div className="multi-attachment-file" key={index}>
                            <Fa icon="paperclip" />&#160;&#160;
                            {attachment.filename}&#160;&#160;
                            <a href="#" className="multi-attachment-delete text-danger" onClick={this.handleDeleteFile.bind(this, attachment.id)}>
                                <Fa icon="times" />
                            </a>
                        </div>
                    )
                }
            </div>
        );
    }
}

MultiAttachmentInput.propTypes = {
    onChange: PropTypes.func,
    attachments: PropTypes.array,
    attachment_type: PropTypes.string,
    button_text: PropTypes.string,
    max_number: PropTypes.number,
    onStartUpload: PropTypes.func,
};

MultiAttachmentInput.defaultProps = {
    attachments: [],
    button_text: "Browse",
    attachment_type: null,
    max_number: -1,
    className: "btn btn-primary btn-block",
    icon: "upload",
    showAttachments: true,
};
