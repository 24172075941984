import 'core-js/stable';
import 'regenerator-runtime/runtime';

require('./libs');
require('./config');

var hot = null;
if (process.env.NODE_ENV === 'develop') {
    require("react-hot-loader/patch");
    hot = require("react-hot-loader").hot;
}

export function register(name, component) {
    if (hot != null) {
        window[name] = hot(module)(component);
    } else {
        window[name] = component;
    }
}


import PlanList from './plans/plan_list';
import UserRegistration from './users/user_register';
import FindPlan from './plans/find_plan';
import SetPassword from './users/set_password';
import FAQ from './faq/faq';
import Contact from './contact/contact';
import ServiceRequestRouter from './claims/service_request_router';

register('PlanList', PlanList);
register('UserRegistration', UserRegistration);
register('FindPlan', FindPlan);
register('SetPassword', SetPassword);
register('FAQ', FAQ);
register('Contact', Contact);
register('ServiceRequestRouter', ServiceRequestRouter);
