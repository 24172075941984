import React, {Component, Fragment} from 'react';
import { Scope, Form, Text } from 'informed';
import { Wizard, Steps, Step, Navigation } from 'react-albus';
import { Redirect } from 'react-router'

import {BootstrapDateInput, BootstrapSelect2, BootstrapTextArea, BootstrapText, BootstrapNumber} from "../autoform/bootstrap";
import { Progress, Row, Col } from "reactstrap";
import ContinueButton from "../util/continue_button";
import Footer from "../util/footer";
import {get, get_select2_ajax} from "../crmapi";
import {getValidators} from "../autoform/validators";
import LoadingWrapper from "../util/loadingWrapper";
import ContentWrapper from "../util/contentWrapper";
import HelpLink from "../util/help_link"

const validators_required = getValidators(["required"]);


export default class DamageWizard extends Component {
    constructor(props) {
        super(props);

        let damage = this.getDamage();

        this.state = {
            loading: true,
            damage_code_tree: {},
            current_code: damage !== undefined ? damage.code_reported || [] : [],
            locations: damage !== undefined ? damage.detail_reported.locations || [{}] : [{}]
        };
    }

    // componentWillReceiveProps(nextProps){
    //     let damage = nextProps.claim.damages[nextProps.match.params.index - 1];
    //     let locations = damage !== undefined ? damage.detail_reported.locations || [{}] : [{}];
    //
    //     if(nextProps.value !== this.state.locations){
    //         this.setState({locations: locations});
    //     }
    // }

    componentDidMount() {
        get(`claims/${this.props.claim.id}/damage_code_tree/`, (response) => {
            this.setState({
                damage_code_tree: response.data,
                loading: false
            });
        });
    }

    getDamage() {
        return this.props.claim.damages[this.props.match.params.index - 1]
    }

    getFormApi(formApi) {
        this.formApi = formApi;
    }

    saveForm(next, data) {
        let component = this;
        this.props.saveData(data, this.formApi, function() {
            next();
            component.formApi.reset();
        });
    }

    addLocation(wizard) {
        let component = this;
        this.setState({
            locations: _.concat(this.state.locations, {})
        }, function() {
            wizard.replace(`location${component.state.locations.length}-location`)
        });
    }

    onCodeChange(index, text, e) {
        let new_code;
        let current_code = this.state.current_code;

        if(text) {
            new_code = this.state.current_code;
            new_code[index] = e.target.value;
        } else {
            new_code = this.state.current_code.splice(0, index);
            new_code.push(parseInt(e.target.value));
        }

        this.getDamage().code_reported = new_code;

        this.setState({current_code: new_code}, function() {
            // for(let i=0; i<new_code.length; ++i) {
            //     let field = `damages[${this.props.match.params.index - 1}].code_reported[${i}]`;
            //     if(this.formApi.fieldExists(field)) {
            //         this.formApi.setValue(field, new_code[i]);
            //     }
            // }
        });
    }

    getCodeList(code_tree) {
        return _(code_tree)
            .map(((code) => [_.toString(code.id), code.user_description ? code.user_description : code.name]))
            .sortBy(((item) => item[1]))
            .value();
    }
    getDamageCodeSteps() {
        let current_code = this.state.current_code;

        let codes = [{
            text: false,
            label: "Choose the type of damage that occurred.",
            data: this.getCodeList(this.state.damage_code_tree)
        }];

        let current_tree = this.state.damage_code_tree;

        for(let index in current_code) {
            let i = parseInt(index);
            let parent_code = _.find(current_tree, {"id": parseInt(current_code[i])});
            if(parent_code === undefined) { break }
            current_tree = parent_code.children;

            if(parent_code.requires_description) {
                let label = parent_code.user_sub_code_label ? parent_code.user_sub_code_label : `Describe the type of ${parent_code.name}`;
                codes.push({text: true, label: label, data: null});
            } else if(current_tree !== undefined) {
                let label = parent_code.user_sub_code_label ? parent_code.user_sub_code_label : `Choose the sub-type of damage for ${parent_code.name}`;
                codes.push({text: false, label: label, data: this.getCodeList(current_tree)});
            }
        }

        return _.map(codes, ((code, index) =>
            [`code${index+1}`, <div>
                {code.text ? (
                    <BootstrapText
                        id={`code${index}`}
                        field={`code_reported[${index}]`}
                        label={code.label}
                        labelClassName="title-label"
                        validate={validators_required}
                        // value={current_code[i + 1]}
                        onChange={this.onCodeChange.bind(this, index, true)}
                    />
                ) : (
                    <BootstrapSelect2
                        id={`code${index}`}
                        field={`code_reported[${index}]`}
                        label={code.label}
                        labelClassName="title-label"
                        validate={validators_required}
                        // value={current_code[i + 1]}
                        data={code.data}
                        onSelect={this.onCodeChange.bind(this, index, false)}
                        emptyLabel="choose option..."
                        valueTransform={parseInt} />
                )}
            </div>]
        ));
    }

    getDamageLocationSteps() {
        let locations = this.state.locations;
        let steps = [];

        const location_types_ajax = get_select2_ajax(`claims/${this.props.claim.id}/damage_location_types/`, 'name', false);
        const location_size_ajax = get_select2_ajax(`damage-location-sizes/`, 'name', true, 'name');

        if(locations.length === 0) {
            locations = [{}];
        }

        for(let index in locations) {
            let i = parseInt(index);
            let location = locations[i].location;

            steps = _.concat(steps, [
                [`location${i+1}-location`,
                    <Scope scope={`detail_reported.locations[${i}]`}>
                        <BootstrapSelect2
                            field="location_id"
                            label="Where is the damage located?"
                            labelClassName="title-label"
                            validate={validators_required}
                            minimumResultsForSearch={-1}
                            emptyLabel="choose location..."
                            initial={location ? [location.id, location.name] : null}
                            ajax={location_types_ajax} />
                    </Scope>
                ],

                [`location${i+1}-area`,
                    <Scope scope={`detail_reported.locations[${i}]`}>
                        <BootstrapSelect2
                            field="size"
                            label="What are the dimensions of the damaged area?"
                            labelClassName="title-label"
                            validate={validators_required}
                            emptyLabel="choose size..."
                            ajax={location_size_ajax}
                            initial={locations[i] ? [locations[i].size, locations[i].size] : null}
                            />

                        <BootstrapNumber
                            field="quantity"
                            label="How many occurrences of this damage type are there in this location?"
                            labelClassName="title-label"
                            type="number"
                            validate={validators_required} />

                        <HelpLink linkText="What does this mean?">
                            <p>Examples:</p>
                                <ul className='pl-4 pr-4'>
                                    <li>If there are three spots in the same area instead of a single stain, enter "3"</li>
                                    <li>If there are two rips in the same area instead of single rip, enter "2"</li>
                                </ul>
                            <p>Only report the number of occurrences in the same area; if there
                                are additional occurrences in a different area please add another location in the next
                                step.
                            </p>
                        </HelpLink>
                    </Scope>
                ]
            ]);
        }

        return steps;

    }

    getStepsHash() {
        let current_code = this.state.current_code;
        return _.join(_.filter(current_code, _.isInteger), "_") + "_" + this.state.locations.length;
    }

    renderSteps(wizard) {
        return (
            <Steps key={this.getStepsHash()}>
                {_.map(this.getSteps(wizard), ((stepItem) =>
                    <Step id={stepItem[0]} key={`step-${stepItem[0]}`}>
                        <Form onSubmit={this.saveForm.bind(this, wizard.next)}
                              initialValues={this.props.claim} getApi={this.getFormApi.bind(this)} key={this.getStepsHash()}>
                            {({ formState }) => (
                                <div>
                                    {/*<code>{JSON.stringify(formState.values)}</code>*/}
                                    <Scope scope={`damages[${this.props.match.params.index - 1}]`}>
                                        {stepItem[1]}
                                    </Scope>
                                </div>
                            )}
                        </Form>
                    </Step>
                ))}
            </Steps>
        );
    }

    getSteps(wizard) {
        let damage = this.getDamage();
        let detail_reported = damage ? damage.detail_reported : null;
        let cause_type = detail_reported ? detail_reported.cause_type : null;
        const cause_type_ajax = get_select2_ajax('damage-cause-types/', 'user_description');

        return _.concat([
            ["cause",
                <div>
                    <Scope scope={`detail_reported`}>
                        <BootstrapSelect2
                            field="cause_type_id"
                            label="What was the primary cause of damage?"
                            initial={cause_type ? [cause_type.id, cause_type.user_description] : null}
                            labelClassName="title-label"
                            ajax={cause_type_ajax}
                            validate={validators_required}
                            placeholder="select primary cause..." />

                        <BootstrapTextArea
                            field="cause"
                            label="Describe how the damage occurred."
                            labelClassName="title-label"
                            validate={validators_required} />
                    </Scope>

                    <HelpLink linkText="See example description">"I tripped on my area rug and spilled orange juice on my sofa."</HelpLink>
                </div>
            ],
            ["date",
                <div>
                    <Scope scope={`detail_reported`}>
                        <BootstrapDateInput
                            field="damage_date"
                            label="When did the damage occur?"
                            labelClassName="title-label"
                            validate={validators_required}
                            options={{maxDate: new Date()}} />
                    </Scope>
                </div>
            ],
        ],
        this.getDamageCodeSteps(),
        this.getDamageLocationSteps(),
        [
            ["location-review",
                <div>
                    <p className="large">Do you have another location of the same type of damage to this item?</p>
                    <button type="button" className="btn btn-outline-primary icon-btn btn-block" onClick={this.addLocation.bind(this, wizard)}>
                        Add another damage location
                        <i className="fas fa-plus"/>
                    </button>
                </div>
            ]
        ]);
    }

    submitForm() {
        if(this.formApi === undefined) {
            return;
        }

        this.formApi.submitForm();
    }

    render() {
        let item = this.props.claim.item;
        if(item === null || item.id === null) {
            return <Redirect to={`/${this.props.claim.ticket}/edit/${this.props.claim.id}/select-item`} />
        }

        let damageIndex = this.props.match.params.index;
        let damages = this.props.claim.damages;

        if(damageIndex < 1 || damageIndex > damages.length + 1) {
            return <Redirect to={`/${this.props.claim.ticket}/edit/${this.props.claim.id}/damage/${damages.length}`} />
        }

        return (
            <Fragment>
                <Wizard
                    history={this.props.history}
                    basename={this.props.match.url}
                    render={(wizard) => (
                        <Fragment>
                            <main>
                                <ContentWrapper>
                                    <h2 className="mb-0">Service Request</h2>
                                    <h1 className="mb-1">Item Damage</h1>
                                    <h4 className="mb-3">{this.props.claim.item.user_description || this.props.claim.item.description}</h4>
                                    <LoadingWrapper loading={this.state.loading}>
                                        <div className="form-progress">
                                            <div className="title">Damage #{damageIndex}</div>
                                            <div className="cardinality">{wizard.steps.indexOf(wizard.step) + 1} of {wizard.steps.length} </div>
                                            <Progress value={(wizard.steps.indexOf(wizard.step) + 1) / wizard.steps.length * 100} />
                                        </div>

                                        {this.renderSteps(wizard)}
                                    </LoadingWrapper>
                                </ContentWrapper>
                            </main>

                            {!this.state.loading &&
                                <Footer plain={true}>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-outline-primary icon-btn-left btn-block"
                                                type="button"
                                                onClick={wizard.steps.indexOf(wizard.step) > 0 ? wizard.previous : this.props.goToPrevious}>
                                                <i className="fas fa-arrow-left"/>
                                                Back
                                            </button>
                                        </Col>


                                        <Col>
                                            {wizard.steps.indexOf(wizard.step) < wizard.steps.length - 1 ? (
                                                <ContinueButton loading={this.props.saving}
                                                                onClick={this.submitForm.bind(this)}/>
                                            ) : (
                                                <ContinueButton type="button" onClick={this.props.goToNext}/>
                                            )}
                                        </Col>
                                    </Row>
                                </Footer>
                            }
                      </Fragment>
                  )} />
            </Fragment>
        );
    }
}
