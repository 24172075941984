import React, {Component, Fragment} from 'react';

import { Row, Col } from "reactstrap";
import Footer from "../util/footer";
import {get} from "../crmapi";
import ContentWrapper from "../util/contentWrapper";
import ServiceRequestListItem from "./service_request_list_item";
import InfiniteScroll from "react-infinite-scroller";
import Fa from "../util/fa";


export default class ServiceRequestList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            tickets: [],
            current_page: {}
        }
    }

    loadMoreTickets(page) {
        if(page > this.state.current_page.total_pages) {
            return;
        }

        let component = this;

        get(`tickets/?page=${page}`, function(response) {
            component.setState({current_page: response.data, tickets: _.concat(component.state.tickets, response.data.results)});
        });
    }

    render() {
        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <a href="/" className="back-link">Back</a>
                        <h1 className="mb-0">Service Requests</h1>
                        <p className="large">These are your active service requests.</p>
                    </ContentWrapper>

                    <div className="gray-panel">
                        <ContentWrapper>
                            <InfiniteScroll
                                loadMore={this.loadMoreTickets.bind(this)}
                                hasMore={this.state.current_page.next !== null}
                                loader={<div className="mt-2" key="loader"><Fa icon="spinner" spin /></div>} >

                                <Fragment>
                                    {_.map(this.state.tickets, ((ticket, index) =>
                                        <ServiceRequestListItem
                                            key={`ticket-${ticket.id}`}
                                            ticket={ticket}
                                            last={index === this.state.tickets.length - 1}
                                            history={this.props.history} />
                                    ))}
                                </Fragment>
                            </InfiniteScroll>
                        </ContentWrapper>
                    </div>
                </main>

                <Footer>
                    <Row>
                        <Col>Need assistance?</Col>
                        <Col className="text-right"><a href="/contact/">Contact Us</a></Col>
                    </Row>
                </Footer>
          </Fragment>
        );
    }
}
