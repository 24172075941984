import React, { Component } from 'react';
import {get} from '../crmapi';

export default class PlanDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    openPDF() {
        this.setState({loading: true});
        let component = this;

        get(`plans/${this.props.plan.id}/plan_document/`, function(response) {
            component.setState({loading: false});

            if(response.data.url) {
                window.open(response.data.url, '_blank');
            } else {
                alert('No PDF found.')
            }
        });
    }

    getItemsString(plan) {
        let names = [];
        let limit = 3;

        for(let x in plan.items) {
            let index = parseInt(x);
            if(index < limit - 1 || (index === limit - 1 && plan.items.length === limit)) {
                names.push(plan.items[index].user_description || plan.items[index].description);
            } else {
                names.push(`${plan.items.length - (limit - 1)} other items`);
                break;
            }
        }

        return _.join(names, ', ');
    }

    render() {
        let plan = this.props.plan;

        return (
            <div className="mb-3">
                <h2>#{plan.plan_number}</h2>
                <p className="large">{this.getItemsString(plan)}</p>
                <button type="button" onClick={this.openPDF.bind(this)} className="btn btn-primary icon-btn" disabled={this.state.loading} style={{minWidth: 150}}>
                    View PDF
                    {this.state.loading ? <i className="fas fa-spinner fa-spin" /> : <i className="fas fa-file-pdf" />}
                </button>
            </div>
        );
    }
}
