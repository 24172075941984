import React from 'react';
import PropTypes from 'prop-types';


export default class BaseSelect2 extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.fieldApi = this.props.fieldApi;
        this.state = {
            value: this.props.value ? this.props.value : this.props.fieldState.value,
        }
    }

    handleChange(event) {
        let value = event.target.value;
        if(this.props.valueTransform !== null) {
            value = this.props.valueTransform(value);
        }

        this.setState({
            value: value,
        });

        const { onSelect } = this.props;

        if(this.fieldApi) {
            this.fieldApi.setValue(value);
        }

        if (onSelect) {
            onSelect(event);
        }
    }

    initSelect2(s) {
        s.off("change");

        let options = {
            theme: 'bootstrap',
            ajax: this.props.ajax,
        };

        if(this.props.templateResult) {
            options.templateResult = this.props.templateResult;
        }

        if(this.props.templateSelection) {
            options.templateSelection = this.props.templateSelection;
        }

        if(this.props.minimumResultsForSearch) {
            options.minimumResultsForSearch = this.props.minimumResultsForSearch;
        }

        if(this.props.minimumInputLength) {
            options.minimumInputLength = this.props.minimumInputLength;
        }

        if(this.props.initial) {
            options.data = [{id: this.props.initial[0], text: this.props.initial[1]}];
        }

        s.select2(options);

        if(this.props.initial) {
            s.val(this.props.initial[0]).trigger('change');
        }

        s.on("change", this.handleChange);
    }

    componentDidMount() {
        let s = $(this.refs.select);
        this.initSelect2(s);
    }

    componentDidUpdate(prevProps, prevState) {
        let s = $(this.refs.select);

        if(prevProps.value !== this.props.value) {
            s.select2('destroy');

            if(this.props.data.length === 0 && !this.props.value) {
                s.empty();
            }

            this.initSelect2(s);
        }
    }

    renderOption(opt) {
        let id = null;
        let name = null;

        if(opt.constructor === Array){
            id = opt[0];
            name = opt[1];
        } else {
            id = opt.id;
            name = opt.name;
        }

        return <option key={id} value={id}>{name}</option>;
    }

    render() {
        const { fieldApi, fieldState, ...props } = this.props;
        const { setValue, setTouched } = fieldApi;
        const {
            data, emptyLabel, ajax, placeholder, templateResult, templateSelection, minimumResultsForSearch,
            minimumInputLength, forwardedRef, className, value, valueTransform, field, initial, ...rest
        } = props;

        rest.onBlur = e => {
            setTouched();
        };

        return (
            <div>
                <select
                    name={field}
                    ref="select"
                    value={this.state.value !== null && !isNaN(this.state.value) ? this.state.value : ''}
                    data-react-select2
                    data-placeholder={this.props.placeholder}
                    // className={classNames(className, 'select2-hidden-accessible')}
                    {...rest} >

                    {this.props.emptyLabel !== null &&
                        <option key='__empty__' value="">{this.props.emptyLabel}</option>
                    }

                    {this.props.data.map((option) =>
                        this.renderOption(option)
                    )}
                </select>
            </div>
        );
    }
}

BaseSelect2.propTypes = {
    data: PropTypes.array,
    onSelect: PropTypes.func,
    emptyLabel: PropTypes.string,
    ajax: PropTypes.object,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    templateResult: PropTypes.func,
    minimumResultsForSearch: PropTypes.number,
    minimumInputLength: PropTypes.number,
    id: PropTypes.string,
    valueTransform: PropTypes.func
};

BaseSelect2.defaultProps = {
    data: [],
    value: undefined,
    emptyLabel: null,
    ajax: undefined,
    disabled: false,
    placeholder: null,
    name: undefined,
    templateResult: null,
    templateSelection: null,
    minimumResultsForSearch: null,
    minimumInputLength: null,
    id: "",
    valueTransform: null
};
