import React, {Component, Fragment} from 'react';
import { Form } from 'informed';
import {get_select2_ajax, get} from "../crmapi";
import LoadingWrapper from "../util/loadingWrapper";
import classNames from 'classnames';
import Footer from "../util/footer";
import ContentWrapper from "../util/contentWrapper";
import Fa from "../util/fa";
import {Row, Col} from "reactstrap";
import ContinueButton from "../util/continue_button";


class ChooseItemListItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            savingItem: false
        };
    }

    setItem(item) {
        if(item.fulfilled || this.props.saving) {
            return;
        }

        this.setState({savingItem: true});

        this.props.onSelect();
    }

    render() {
        let item = this.props.item;
        let claim = this.props.claim;
        let selected = claim.item_id === item.id;

        return (
            <button className={classNames("item-selector", {"item-selector-disabled": item.fulfilled})}
                    onClick={this.setItem.bind(this)}
                    disabled={item.fulfilled} key={`item-${item.id}`}>
                <span className="title">
                    {item.user_description || item.description}
                    {selected && <small className="text-primary ml-4"><Fa icon="check" /> Selected</small>}
                </span>

                <span className="subtitle">{item.item_category ? item.item_category.name : ""}</span>

                {item.fulfilled && <i className="fulfilled-warning">Item fulfilled, not eligible for new service requests</i>}

                <span className="control">
                    {item.fulfilled ? (
                        <i className="fas fa-ban" />
                    ) : (
                        <span className={classNames("btn btn-primary", {"disabled": this.props.saving})}>
                            {this.state.savingItem ? (
                                <i className="fas fa-spinner fa-spin" />
                            ) : (
                                <i className="fas fa-arrow-right" />
                            )}
                        </span>
                    )}
                </span>
            </button>
        );
    }
}

export default class WizardChooseItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            plan: null,
        };
    }

    componentDidMount() {
        get(`plans/${this.props.claim.plan_id}/`, (response) => {
            this.setState({
                plan: response.data,
                loading: false
            });
        });
    }

    itemHasEnoughInfo(item) {
        return item.retail_price && item.item_category && item.item_type && item.item_material && (item.user_description || item.description);
    }

    setItem(item) {
        let component = this;

        // if(item.claim_count > 0) {
        //     alert("You have previously submitted a service request on this item. If this is a new request please proceed, otherwise please check Service Request Status for updates.");
        //     return
        // }

        this.props.saveData({item_id: item.id}, null, function() {
            component.itemSelected(item);
        });
    }

    itemSelected(item) {
        if(this.itemHasEnoughInfo(item)) {
            this.props.history.push(`/${this.props.claim.ticket}/edit/${this.props.claim.id}/damage/1`);
        } else {
            this.props.history.push(`/${this.props.claim.ticket}/edit/${this.props.claim.id}/edit-item`);
        }
    }

    addItem() {
        this.props.history.push('./add-item');
    }

    render() {
        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <h2 className="mb-0">Service Request</h2>
                        <h1>Choose Item</h1>

                        <LoadingWrapper loading={this.state.loading}>{() => (
                            <Fragment>
                                <p className="large">Choose one of the items associated with Plan #{this.state.plan.plan_number}</p>
                            </Fragment>)}
                        </LoadingWrapper>
                    </ContentWrapper>

                    {!this.state.loading &&
                        <div className="gray-panel">
                            <ContentWrapper>
                                {_.map(this.state.plan.items, (item) =>
                                    <ChooseItemListItem key={`item-${item.id}`} item={item} claim={this.props.claim} onSelect={this.setItem.bind(this, item)} saving={this.props.saving} />
                                )}

                                <button type="button" className="btn btn-outline-primary icon-btn btn-block" onClick={this.addItem.bind(this)}>
                                    Add Item
                                    <Fa icon="plus" />
                                </button>
                            </ContentWrapper>
                        </div>
                    }
                </main>

                <Footer plain={true}>
                    <Row>
                        <Col xs={6}>
                            <button
                                className="btn btn-outline-primary icon-btn-left btn-block"
                                type="button"
                                onClick={this.props.goToPrevious}>
                                <i className="fas fa-arrow-left" />
                                Back
                            </button>
                        </Col>

                        <Col xs={6}>
                            <ContinueButton type="button" onClick={this.itemSelected.bind(this, this.props.claim.item)} disabled={this.props.claim.item_id === null || this.props.claim.item_id === null} />
                        </Col>
                    </Row>

                </Footer>
            </Fragment>
        );
    }
}

