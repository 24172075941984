import React, {Component, Fragment} from 'react';

import { Row, Col} from "reactstrap";
import { Link } from 'react-router-dom';
import Footer from "../util/footer";
import {crmrequest, get, get_select2_ajax} from "../crmapi";
import LoadingWrapper from "../util/loadingWrapper";
import ContentWrapper from "../util/contentWrapper";
import classNames from 'classnames';
import {joinedListLimit} from "../util/util";


export default class ServiceRequestClaimDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            claim: null,
            examination: {},
        }
    }

    componentDidMount() {
        let component = this;
        let claim_id = this.props.match.params.claim_id;

        get(`claims/${claim_id}/`, function(response) {
            component.setState({claim: response.data});

            get(`claims/${claim_id}/examination/`, function(response) {
                component.setState({examination: response.data, loading: false});
            });
        });
    }

    renderStatus() {
        let claim = this.state.claim;

        return (
            <span className={classNames("badge", `status-${claim.status_detail.css_class}`)}>
                {claim.status_detail.description}
            </span>
        );
    }

    getResolutionString() {
        if(!this.state.examination.id) {
            return 'Pending Decision';
        }

        let resolutions = _(this.state.examination.resolutions)
            .filter(((resolution) => _.includes(resolution.claims, this.state.claim.id)));

        if(resolutions.value().length === 0) {
            return 'Pending Decision';
        }

        return joinedListLimit(
            _(resolutions).map(((resolution) => resolution.option_type_display)).uniq().value(), 10
        );
    }

    renderResolutionDetails() {
        return <Fragment>
            <hr/>
            <h5 style={{fontSize: 20}}>Resolution</h5>
            <dl className="row">
                <dt className="col-4">Claim Decision</dt>
                <dd className="col-8">{this.renderStatus()}</dd>

                {(this.state.examination.id && !_.includes(["closed", "unresolved"], this.state.claim.status)) &&
                    <Fragment>
                        <dt className="col-4">Claim Resolution</dt>
                        <dd className="col-8">{this.getResolutionString()}</dd>
                    </Fragment>
                }

                {this.state.examination.id &&
                    <Fragment>

                        {this.state.examination.selected_vendor &&
                            <Fragment>
                                <dt className="col-4">Service Rep</dt>
                                <dd className="col-8">{this.state.examination.selected_vendor.name}</dd>
                            </Fragment>
                        }
                    </Fragment>
                }
            </dl>
        </Fragment>
    }

    renderItemDetails() {
        let claim = this.state.claim;

        if(claim.item === null) {
            return null;
        }

        return <Fragment>
            <hr/>
            <h5 style={{fontSize: 20}}>Item Details</h5>
            <dl className="row">
                <dt className="col-4">Item Name</dt>
                <dd className="col-8">{claim.item.user_description || claim.item.description}</dd>

                <dt className="col-4">Item Category</dt>
                <dd className="col-8">{claim.item.item_category.name}</dd>

                <dt className="col-4">Material</dt>
                <dd className="col-8">{claim.item.item_material.name}</dd>

                <dt className="col-4">Retail Amount</dt>
                <dd className="col-8">${claim.item.retail_price}</dd>
            </dl>
        </Fragment>
    }

    renderDamageDetails() {
        let damages = this.state.claim.damages;

        return _.map(damages, ((damage, index) =>
            <Fragment>
                <hr/>
                <h5 style={{fontSize: 20}}>Damage {damages.length > 1 ? `#${index+1}` : ''} Details</h5>
                <dl className="row">
                    <dt className="col-4">Damage</dt>
                    <dd className="col-8 damage-code-list">
                        {_.map(damage.code_reported_names, ((code) =>
                            <span>{code}</span>
                        ))}
                    </dd>

                    {_.map(damage.detail_reported.locations, ((location, index2) =>
                        <Fragment>
                            <dt className="col-4">Damage Location {damage.detail_reported.locations.length > 1 ? `#${index2+1}` : ''}</dt>
                            <dd className="col-8">{location.location.name}</dd>

                            <dt className="col-4">Damage Size</dt>
                            <dd className="col-8">{location.size}</dd>

                            <dt className="col-4">Damage Quantity</dt>
                            <dd className="col-8">{location.quantity}</dd>
                        </Fragment>
                    ))}

                    <dt className="col-4">How Damage Occurred</dt>
                    <dd className="col-8">{damage.detail_reported.cause_type.user_description}</dd>

                    <dt className="col-4">Incident Description</dt>
                    <dd className="col-8">{damage.detail_reported.cause}</dd>
                </dl>
            </Fragment>
        ));
    }

    render() {
        let claim = this.state.claim;

        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <Link to={`/${this.props.match.params.ticket_id}`} className={"back-link"}>Back</Link>

                        <LoadingWrapper loading={this.state.loading}>{(() =>
                            <Fragment>
                                <h2 className="mb-0">Service Request #{claim.ticket}</h2>
                                <h1>Claim Details</h1>
                                <h2 className="mt-3">Claim {claim.name}</h2>
                                {this.renderResolutionDetails()}
                                {this.renderItemDetails()}
                                {this.renderDamageDetails()}
                            </Fragment>
                        )}</LoadingWrapper>
                    </ContentWrapper>
                </main>

                <Footer>
                    <Row>
                        <Col>Need assistance?</Col>
                        <Col className="text-right"><a href="/contact/">Contact Us</a></Col>
                    </Row>
                </Footer>
          </Fragment>
        );
    }
}
