import React, {Component, Fragment} from 'react';
import { Form } from 'informed';
import Footer from "../util/footer";
import  { Row, Col } from 'reactstrap';
import ContentWrapper from "../util/contentWrapper";
import Fa from "../util/fa";
import {crmrequest} from "../crmapi";
import {addFormErrors} from "../autoform/util";
import {deleteIn} from "immutable-setter";


class DamageItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleting: false
        };
    }

    reviewDamage() {
        let { claim, index, history } = this.props;

        history.push(
            `/${claim.ticket}/edit/${claim.id}/damage/${index+1}`
        );
    }

    deleteDamage() {
        this.props.onDelete();
        this.setState({
            deleting: true,
        });
    }

    render() {
        let { damage, index, canDelete, disableDelete } = this.props;

        return (
            <div className="damage-review-item">
                <span className="icon-circle"><Fa icon="check"/></span>&#160;&#160;
                <span className="title">Damage #{index+1}</span>&#160;&#160;&#160;&#160;
                <span className="damage-code-list">
                    {_.map(damage.code_reported_names, ((code) =>
                        <span>{code}</span>
                    ))}
                </span>
                <button className="btn btn-xs btn-outline-primary ml-3" onClick={this.reviewDamage.bind(this)}>Edit</button>
                {canDelete &&
                    <button className="delete-btn" onClick={this.deleteDamage.bind(this)} disabled={this.state.deleting || disableDelete}>
                        {this.state.deleting ? <Fa icon="spinner" spin/> : <Fa icon="times"/>}
                    </button>
                }
            </div>
        );
    }
}


export default class WizardMoreToAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    addDamage(claim) {
        this.props.history.push(
            `/${claim.ticket}/edit/${claim.id}/damage/${claim.damages.length + 1}`
        );
    }

    addClaim() {
        let component = this;

        this.props.clearClaim(function () {
            component.props.history.push(
                `/${component.props.claim.ticket}/create/`
            );
        })
    }

    deleteDamage(claim, index) {
        this.setState({loading: true});
        let component = this;

        let url = `claims/${claim.id}/`;

        let data = {damages: deleteIn(claim.damages, [index])};

        crmrequest("PATCH", url, function(response){
            component.props.updateClaimState(response.data, function() {
                component.setState({loading: false});
            });
        }, {data: data});
    }

    renderHaveMoreToAdd() {
        return <div>
            <h1>Have More to Add?</h1>

            <p className="large mb-1">Have a different type of damage to this item, or have another item that was damaged you want to add to this request?</p>

            <button type="button" className="btn btn-outline-primary icon-btn btn-block mb-4 mt-4" onClick={this.addClaim.bind(this)}>
                Add another claim
                <i className="fas fa-plus"/>
            </button>

            {_.map(this.props.ticket.claims, ((claim) =>
                <Fragment key={`claim-${claim.id}`}>
                    <hr />

                    <h3>Claim {claim.name}</h3>

                    {_.map(claim.damages, ((damage, index) =>
                        <DamageItem
                            key={`damage-${index}`} damage={damage} index={index} claim={claim} history={this.props.history}
                            canDelete={claim.damages.length > 1} onDelete={this.deleteDamage.bind(this, claim, index)}
                            disableDelete={this.state.loading}
                        />
                    ))}

                    <button type="button" className="btn btn-outline-primary icon-btn btn-block mt-4 mb-4" onClick={this.addDamage.bind(this, claim)}>
                        Add another damage listing
                        <i className="fas fa-plus"/>
                    </button>
                </Fragment>
            ))}
        </div>
    }

    render() {
        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <h2 className="mb-0">Service Request</h2>
                        {this.renderHaveMoreToAdd()}
                    </ContentWrapper>
                </main>

                <Footer plain={true}>
                    <Row className="mb-3">
                        <Col xs={6}>
                            <button
                                className="btn btn-outline-primary icon-btn-left btn-block"
                                type="button"
                                onClick={this.props.goToPrevious}>
                                <i className="fas fa-arrow-left" />
                                Back
                            </button>
                        </Col>
                    </Row>

                    <button
                        disabled={this.state.loading}
                        className="btn btn-primary btn-block"
                        type="button"
                        onClick={this.props.goToNext}>
                        I'm Ready to Review and Submit
                    </button>
                </Footer>
            </Fragment>
        );
    }
}

