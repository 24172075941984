import React, {Component, Fragment} from 'react';
import { Scope, Form, Text } from 'informed';
import { Wizard, Steps, Step, Navigation } from 'react-albus';

import {BootstrapDateInput, BootstrapSelect2, BootstrapTextArea, BootstrapText, BootstrapNumber} from "../autoform/bootstrap";
import { Progress, Row, Col } from "reactstrap";
import ContinueButton from "../util/continue_button";
import Footer from "../util/footer";
import {crmrequest, get, get_select2_ajax} from "../crmapi";
import {getValidators} from "../autoform/validators";
import LoadingWrapper from "../util/loadingWrapper";
import ContentWrapper from "../util/contentWrapper";
import HelpLink from "../util/help_link"
import {addFormErrors} from "../autoform/util";

const validators_required = getValidators(["required"]);


export default class ItemWizard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            saving: false,
            item: this.props.newItem ? {} : this.props.claim.item
        }
    }

    getFormApi(formApi) {
        this.formApi = formApi;
    }

    saveForm(wizard, data) {
        let thisComponent = this;
        this.setState({saving: true});

        data.plan = this.props.claim.plan_id;

        let url = 'plans-items/';
        let method = 'POST';

        if(this.state.item.id) {
            url = `plans-items/${this.state.item.id}/`;
            method = 'PATCH';
        }

        crmrequest(method, url, function(response){
            thisComponent.setState(
                {saving: thisComponent.props.newItem, item: response.data},
                function() {
                    if(thisComponent.props.newItem) {
                        thisComponent.props.saveData({item_id: thisComponent.state.item.id}, null, function() {
                            thisComponent.props.history.replace('../edit-item/description');
                            thisComponent.props.history.push('../edit-item/category');
                        });
                    } else {
                        if(wizard.steps.indexOf(wizard.step) < wizard.steps.length - 1) {
                            wizard.next();
                        } else {
                            thisComponent.props.goToNext();
                        }
                    }
                }
            );
        }, {data: data}).catch(function(error) {
            thisComponent.setState({saving: false});
            if(error.response.status === 400 && thisComponent.formApi !== undefined && thisComponent.formApi !== null) {
                addFormErrors(error.response.data, thisComponent.formApi);
            }
        });
    }

    renderSteps(wizard) {
        return (
            <Steps>
                {_.map(this.getSteps(wizard), ((stepItem) =>
                    <Step id={stepItem[0]} key={`step-${stepItem[0]}`}>
                        <Form onSubmit={this.saveForm.bind(this, wizard)} getApi={this.getFormApi.bind(this)} initialValues={this.state.item}>
                            {({ formState }) => (
                                <div>
                                    {/*<code>{JSON.stringify(formState.values)}</code>*/}
                                    {stepItem[1]}
                                </div>
                            )}
                        </Form>
                    </Step>
                ))}
            </Steps>
        );
    }

    getSteps(replace) {
        const category_ajax = get_select2_ajax('item-categories/', 'name');
        const material_ajax = get_select2_ajax('item-materials/', 'name');
        const type_ajax = get_select2_ajax(`item-types/?item_categories=${this.state.item.item_category_id}`, 'name');

        let steps = [["description",
            <BootstrapText
                field="user_description"
                label="If your item is not already listed under your plan, you can add it here and it will be verified later."
                labelClassName="title-label"
                validate={validators_required}
                placeholder="Item Name"
                helpText='Please be as specific as possible. For example, "Summit Oak Dining Table" is better than "table and chairs" or "dining room set".'
                initialValue={this.state.item.description} />
        ]];

        if(!this.props.newItem) {
            steps = _.concat(steps, [
                ["category",
                    <BootstrapSelect2
                        field="item_category_id"
                        label="What is the general category for the item?"
                        labelClassName="title-label"
                        validate={validators_required}
                        ajax={category_ajax}
                        placeholder="select category..."
                        initial={this.state.item.item_category ? [this.state.item.item_category.id, this.state.item.item_category.name] : null}
                        />
                ],
                ["type",
                    <BootstrapSelect2
                        field="item_type_id"
                        label="What is the item's type?"
                        labelClassName="title-label"
                        validate={validators_required}
                        ajax={type_ajax}
                        initial={this.state.item.item_type ? [this.state.item.item_type.id, this.state.item.item_type.name] : null}
                        placeholder="select type..."/>
                ],
                ["material",
                    <BootstrapSelect2
                        field="item_material_id"
                        label="What is the item's material type?"
                        labelClassName="title-label"
                        validate={validators_required}
                        ajax={material_ajax}
                        initial={this.state.item.item_material ? [this.state.item.item_material.id, this.state.item.item_material.name] : null}
                        placeholder="select material..."/>
                ],
                // ["quantity",
                //     <BootstrapNumber
                //         field="quantity"
                //         label="Did you purchase multiples of this item? If so, how many?"
                //         labelClassName="title-label"
                //         validate={validators_required}
                //         initialValue={1}/>
                // ],
            ]);
        }

        // if(!this.state.item.description) {
        //     steps = _.concat(steps, [
        //         ["price",
        //             <BootstrapText
        //                 field="retail_price"
        //                 type="number"
        //                 label="What was the retail price of the item?"
        //                 labelClassName="title-label"
        //                 validate={validators_required}/>
        //         ]
        //     ]);
        // }

        return steps;
    }

    submitForm() {
        if(this.formApi === undefined) {
            return;
        }

        this.formApi.submitForm();
    }

    render() {
        return (
            <Fragment>
                <Wizard
                    history={this.props.history}
                    basename={this.props.match.url}
                    render={(wizard) => (
                        <Fragment>
                            <main>
                                <ContentWrapper>
                                    <h2 className="mb-0">Service Request</h2>
                                    <h1>{this.props.newItem ? "Add an Item" : "Edit Item"}</h1>
                                    <LoadingWrapper loading={this.state.loading}>
                                        {this.renderSteps(wizard)}
                                    </LoadingWrapper>
                                </ContentWrapper>
                            </main>

                            {!this.state.loading &&
                                <Footer plain={true}>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-outline-primary icon-btn-left btn-block"
                                                type="button"
                                                onClick={wizard.steps.indexOf(wizard.step) > 0 ? wizard.previous : this.props.goToPrevious}>
                                                <i className="fas fa-arrow-left"/>
                                                Back
                                            </button>
                                        </Col>


                                        <Col>
                                            <ContinueButton loading={this.state.saving} onClick={this.submitForm.bind(this)}/>
                                        </Col>
                                    </Row>
                                </Footer>
                            }
                      </Fragment>
                  )} />
            </Fragment>
        );
    }
}
