function flatten_errors(errors) {
    let all_errors = {};

    for(let key in errors) {
        if(_.isArray(errors[key])) {
            if(_.isString(errors[key][0])) {
                all_errors[key] = errors[key];
            }else {
                for(let index in errors[key]) {
                    let sub_errors = flatten_errors(errors[key][index]);
                    for(let key2 in sub_errors) {
                        all_errors[key + `[${index}].` + key2] = sub_errors[key2];
                    }
                }
            }
        } else {
            let sub_errors = flatten_errors(errors[key]);
            for(let key2 in sub_errors) {
                all_errors[key + '.' + key2] = sub_errors[key2];
            }
        }
    }

    return all_errors;
}

export function addFormErrors(errors, formApi) {
    let all_errors = flatten_errors(errors);
    for(let key in all_errors) {
        if(formApi.fieldExists(key)) {
            formApi.setError(key, <div dangerouslySetInnerHTML={{__html: all_errors[key]}}/>);
        }
    }
}
