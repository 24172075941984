import React, {Component, Fragment} from 'react';

import { Row, Col, Button } from "reactstrap";
import Footer from "../util/footer";
import {get} from "../crmapi";
import LoadingWrapper from "../util/loadingWrapper";
import ContentWrapper from "../util/contentWrapper";
import Fa from "../util/fa";
import { Message } from "./service_request_messages";
import {Link} from "react-router-dom";
import classNames from 'classnames';


class LatestMessageBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            message: null,
        }
    }

    loadMessage() {
        let component = this;

        get(`messages/?page_size=1&ticket=${this.props.ticket.id}&from_type=agent`, function(response) {
            let message = null;
            if(response.data.results.length > 0) {
                message = response.data.results[0];
            }
            component.setState({message: message, loading: false});
        });
    }

    componentDidMount() {
        this.loadMessage();
    }

    goToMessages(e) {
        e.preventDefault();
        this.props.history.push(`./${this.props.ticket.id}/messages`);
    }

    render() {
        let message = this.state.message;

        return (
            <LoadingWrapper loading={this.state.loading}>
                {message ? (
                    <Message message={message} replyClicked={this.goToMessages.bind(this)} />
                ) : (
                    <div>
                        <div className="text-muted mb-3">No recent messages</div>
                        <button className="btn btn-primary btn-sm" onClick={this.goToMessages.bind(this)}>View all messages</button>
                    </div>
                )}
            </LoadingWrapper>
        );
    }
}


export default class ServiceRequestDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ticket: null,
        }
    }

    componentDidMount() {
        let component = this;
        let ticket_id = this.props.match.params.ticket_id;

        get(`tickets/${ticket_id}/`, function(response) {
            component.setState({ticket: response.data, loading: false});
        });
    }

    onViewDetailsOrEdit(claim) {
        if(claim.status === 'portal_draft') {
            this.props.history.push(`/${this.state.ticket.id}/edit/${claim.id}/select-item`);
        } else {
            this.props.history.push(`/${this.state.ticket.id}/${claim.id}`);
        }
    }

    render() {
        let ticket = this.state.ticket;
        let claims = [];

        if(ticket) {
            claims = ticket.claims;
        }

        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <Link to="/" className="back-link">Back</Link>

                        <LoadingWrapper loading={this.state.loading}>{(() =>
                            <Fragment>
                                <h2 className="mb-0">Service Request {ticket.name}</h2>
                                <h1>Details</h1>
                            </Fragment>
                        )}</LoadingWrapper>
                    </ContentWrapper>

                    {!this.state.loading &&
                        <Fragment>
                            <div className="gray-panel">
                                <ContentWrapper>
                                    <h2 className="mt-1 mb-4"><small><Fa icon="envelope"/></small> &#160;Messages</h2>
                                    <LatestMessageBox ticket={this.state.ticket} history={this.props.history} />
                                </ContentWrapper>
                            </div>

                            <ContentWrapper>
                                <h2 className="mt-4 mb-4">Claims</h2>
                                {claims.length === 0 && <i className="text-muted">No claims found</i> }
                                {_.map(claims, ((claim, index) =>
                                    <Fragment key={`claim-${claim.id}`}>
                                        {index !== 0 && <hr/>}

                                        <Row key={`claim-${claim.id}`} className="mb-4">
                                            <Col xs={8}>
                                                <h5 style={{fontSize: 20}}>
                                                    <small><Fa icon="file"/></small>
                                                    &#160;&#160;Claim {claim.name}
                                                </h5>

                                                <span className={classNames("badge", "mb-2", `status-${claim.status_detail.css_class}`)}>
                                                    {claim.status_detail.description}
                                                </span>

                                                <br/>

                                                {claim.item ? claim.item.user_description || claim.item.description : (
                                                    <i className="text-muted">no item</i>
                                                )}


                                            </Col>
                                            <Col xs={4} className="text-right">
                                                <Button color="primary" outline size="xs" style={{minWidth: 80}}
                                                        onClick={this.onViewDetailsOrEdit.bind(this, claim)}>
                                                    {claim.status === 'portal_draft' ? "Edit" : "View Details"}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                ))}
                            </ContentWrapper>
                        </Fragment>
                    }
                </main>

                <Footer>
                    <Row>
                        <Col>Need assistance?</Col>
                        <Col className="text-right"><a href="/contact/">Contact Us</a></Col>
                    </Row>
                </Footer>
          </Fragment>
        );
    }
}
