import axios from "axios";

export function refresh_token(on_complete) {
    axios.post('/api/refresh-token/').then(function (response) {
        if(response.status === 200) {
            if(response.data.token === null) {
                document.location = '/plans/find-plan/?next=' + document.location.pathname;
            }

            JSVARS.crm_api_token = response.data.token;
            on_complete();
        }
    })
}

export function crmrequest(method, url, on_complete, params) {
    let token = JSVARS.crm_api_token;

    if(token == null) {
        return Promise.reject();
    }

    let config = {
        method: method,
        url: JSVARS.crm_api_url + url,
        headers: {"Authorization": `Token ${token}`},
    };

    return axios(_.assign(config, params)).catch(function (error) {
        if(error.response.status === 403 && error.response.data.detail === 'Invalid token.') {
            refresh_token(function () {
                crmrequest(method, url, on_complete, params)
            });

            return new Promise(() => {});
        } else if(error.response.status === 403 || error.response.status === 404) {
            document.location = '/404/';

            return new Promise(() => {});
        }

        return Promise.reject(error);
    }).then(function(response){
        on_complete(response);
    });
}

export function get(url, on_complete, params) {
    return crmrequest('get', url, on_complete, {params: params});
}

export function post(url, data, on_complete) {
    return crmrequest('post', url, on_complete, {data: data});
}

export function get_select2_ajax(url, name_field, pagination, id_field) {
    if(pagination === undefined) {
        pagination = true;
    }

    if(id_field === undefined) {
        id_field = 'id';
    }

    return {
        url: JSVARS.crm_api_url + url,
        headers: {
            "Authorization" : `Token ${JSVARS.crm_api_token}`,
            "Content-Type" : "application/json",
        },
        dataType: 'json',
        processResults: function (data) {
            let results = data;
            let next = null;

            if(pagination) {
                results = data.results;
                next = data.next;
            }

            return {
                results: results.map(function(item) { return {id: item[id_field], text: item[name_field]}; }),
                pagination: {
                    more: next !== null
                }
            };
        },
        data: function (params) {
            return {
                search: params.term,
                page: params.page || 1
            };
        },
        error: function(response, textStatus, errorThrown) {
            if(response.status === 403) {
                this.xhr();
                this.transport();
                refresh_token(function() { location.reload() });
            }
        }
    };
}
