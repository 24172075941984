import React, { Component } from 'react';
import PropTypes from "prop-types";


export default class ContinueButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { loading, children, disabled, ...rest } = this.props;

        return (
            <button className="btn btn-primary btn-block icon-btn" {...rest} disabled={loading || disabled}>
                {children ? children : "Continue"}
                {loading ? (
                    <i className="fas fa-spinner fa-spin"/>
                ) : (
                    <i className="fas fa-arrow-right" />
                )}
            </button>
        );
    }
}

ContinueButton.propTypes = {
    loading: PropTypes.bool,
};
