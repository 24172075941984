import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { uniqueId} from 'lodash';
import classNames from 'classnames';
const moment = require('moment');


export default class DateInputBasic extends React.Component {
    constructor(props) {
        super(props);

        this.state = {id: this.props.id || uniqueId("field-")};
    }

    componentDidMount() {
        const {onChange, fieldApi} = this.props;
        const {setValue, setTouched} = fieldApi;
        let s = $(this.refs['input']);
        let component = this;

        let options = this.props.options;
        options.format = this.props.dateFormat;
        options.useCurrent = false;

        if (s.val()) {
            options.date = moment(s.val(), this.props.dateFormat);
        }

        s.datetimepicker(options);

        s.on('blur', function () {
            $(this).data('datetimepicker').hide();
            setTouched();
        }).on('change.datetimepicker', function(e) {
            setValue(e.target.value);
            if (onChange) {
                onChange(e);
            }
        }).on('focus', function(e) {
            if (s.val().length === 0) {
                s.val(moment().format(component.props.dateFormat));
            }
        });
    }

    render() {
        const { fieldApi, fieldState, ...props } = this.props;
        let {
            forwardedRef, value, id, dateFormat, ...rest
        } = props;

        value = value ? value : fieldState.value;

        if(value !== undefined && value !== null) {
            value = moment(value, ["YYYY-MM-DD", dateFormat]).format(dateFormat);
        }

        return (
            <InputGroup>
                <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-calendar" aria-hidden="true"/></span>
                </div>
                <input
                    defaultValue={value}
                    disabled={this.props.disabled}
                    data-toggle="datetimepicker"
                    data-target={"#" + this.state.id}
                    id={this.state.id}
                    ref="input"
                    {...rest}
                />
            </InputGroup>
        );
    }
}

DateInputBasic.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.object,
    dateFormat: PropTypes.string,
};

DateInputBasic.defaultProps = {
    label: '',
    type: 'text',
    options: {},
    fieldState: {},
    id: null,
    dateFormat: 'MM/DD/YYYY'
};
