import React, {Component, Fragment} from 'react';
import { Form } from 'informed';
import Footer from "../util/footer";
import  { Row, Col } from 'reactstrap';
import ContentWrapper from "../util/contentWrapper";
import classNames from 'classnames';
import moment from 'moment';
import Fa from '../util/fa';
import { post } from '../crmapi'


export default class WizardReview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
        };
    }

    reviewDetails(claim) {
        this.props.history.push(
            `/${claim.ticket}/edit/${claim.id}/select-item`
        );
    }

    submitForDecision() {
        if (!this.state.saving) {
            this.setState({saving: true});
            let component = this;

            post(`tickets/${this.props.ticket.id}/submit_all_claims/`, null, function () {
                component.props.goToNext();
            }).catch(function(){
                this.setState({saving: false});
            }.bind(this));
        }
    }

    onDisclaimerRead(e) {
        let state = {disclaimer_read: e.target.checked};
        this.setState(state);
    }

    renderClaim(claim, index) {
        return (
            <div className={classNames("card", {"mt-3": index !== 0})} key={`claim-${claim.id}`}>
                <div className="card-header">
                    <h3 className="mb-0">Claim {claim.name}</h3>
                </div>

                <div className="card-body">
                    <dl className="row mb-1">
                        <dt className="col-4">Plan</dt>
                        <dd className="col-8">
                            {claim.plan ? (
                                claim.plan.plan_number
                            ) : (
                                <span className="text-muted">No plan</span>
                            )}
                        </dd>

                        <dt className="col-4">Item</dt>
                        <dd className="col-8">
                            {claim.item ? (
                                claim.item.user_description || claim.item.description
                            ) : (
                                <span className="text-muted">No item</span>
                            )}
                        </dd>
                    </dl>

                    {_.map(claim.damages, ((damage, index) =>
                        <Fragment key={`damage-${damage.id}`}>
                            {claim.damages.length > 1 &&
                                <h3 style={{fontSize: 21}}>Damage #{index+1}</h3>
                            }

                            <div className="card-inner">
                                <dt>Damage</dt>
                                <dd className="damage-code-list">
                                    {_.map(damage.code_reported_names, ((code) =>
                                        <span key={code}>{code}</span>
                                    ))}
                                </dd>

                                <dt>Incident Type</dt>
                                <dd>{damage.detail_reported.cause_type.name}</dd>

                                <dt>Description</dt>
                                <dd>{damage.detail_reported.cause}</dd>

                                <dt>Incident Date</dt>
                                <dd>
                                    {damage.detail_reported.damage_date ? (
                                        moment(damage.detail_reported.damage_date).format('M/D/YY')
                                    ): (
                                        <span className="text-muted">no date</span>
                                    )}
                                </dd>

                                {_.map(damage.detail_reported.locations, ((location, index) =>
                                    <Row key={`location-${location.id}`}>
                                        <Col>
                                            <dt>Location #{index+1}</dt>
                                            <dd>{location.location.name}</dd>
                                        </Col>

                                        <Col>
                                            <dt>Size</dt>
                                            <dd>{location.size}</dd>
                                        </Col>

                                        <Col>
                                            <dt>Quantity</dt>
                                            <dd>{location.quantity}</dd>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </Fragment>
                    ))}

                    <button className="btn btn-outline-primary btn-sm" style={{minWidth: 165}} onClick={this.reviewDetails.bind(this, claim)}>
                        Edit Claim
                    </button>

                     <div className="form-group mb-2 mt-2">
                        <div className="checkbox">
                            <div className="custom-control custom-checkbox">
                                <input
                                    onChange={this.onDisclaimerRead.bind(this)}
                                    className="custom-control-input form-check-input"
                                    id="disclaimer_read_checkbox"
                                    type="checkbox"
                                    checked={this.state.disclaimer_read} />

                                <label className="custom-control-label pt-0" htmlFor="disclaimer_read_checkbox">
                                   <dd className='text-disclaimer'><strong>IMPORTANT:</strong> The information that you submit will be used to determine the
                                        eligibility of your claim(s). Once submitted, a service request may not be updated, altered,
                                        or changed. By submitting this service request, you acknowledge and agree that you may not alter
                                        or add additional information to your claim(s) or submit another service request with claim(s)
                                        for the same damage. If you do not understand or agree to these terms, please do not submit your
                                        service request; instead <a href="/contact" target='_blank'>contact us</a> for assistance.
                                    </dd>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    render() {
        return (
            <Fragment>
                <main>
                    <ContentWrapper>
                        <h2 className="mb-0">Service Request</h2>
                        <h1>Review</h1>
                    </ContentWrapper>

                    <div className="gray-panel">
                        <ContentWrapper>
                            {_.map(this.props.ticket.claims, this.renderClaim.bind(this))}
                        </ContentWrapper>
                    </div>

                </main>

                <Footer plain={true}>
                    <Row className="mb-3">
                        <Col xs={6}>
                            <button
                                className="btn btn-outline-primary icon-btn-left btn-block"
                                type="button"
                                onClick={this.props.goToPrevious}>
                                <i className="fas fa-arrow-left" />
                                Back
                            </button>
                        </Col>
                    </Row>

                    <button className="btn btn-primary btn-block" type="button" onClick={this.submitForDecision.bind(this)} disabled={this.state.saving || !this.state.disclaimer_read?"disabled":""}>
                        {this.state.saving && <Fa icon="spinner" spin className="mr-2" /> }
                        Submit Service Request
                    </button>
                </Footer>
            </Fragment>
        );
    }
}

