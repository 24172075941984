import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import classNames from "classnames";

import zxcvbn from 'zxcvbn';

const isTooShort = (password, minLength) => password.length < minLength;
const isAllDigits = (password) => /^\d+$/.test(password);

export default class SetPassword extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.getStrengthTitle = this.getStrengthTitle.bind(this);
        this.getProgressColor = this.getProgressColor.bind(this);
        this.state = {
            score: 0,
            password: '',
            form_errors: this.props.form_errors || {}
        }
    }

    handleChange(e) {
        const password = e.target.value;

        let score = 0;
        let result = null;

        // always sets a zero score when min length requirement is not met
        // avoids unnecessary zxcvbn computations (CPU intensive)
        if (isTooShort(password, 8) === false && isAllDigits(password) === false) {
            result = zxcvbn(password);
            score = result.score;
        }

        this.setState({score: score, password: password, form_errors: {}});
    }

    getStrengthTitle() {
        if(this.state.score === 0) {
            if(isTooShort(this.state.password, 8)) {
                return 'Too short';
            } else if(isAllDigits(this.state.password)) {
                return 'All digits';
            }
        }

        let titles = ['Weak', 'Weak', 'Okay', 'Good', 'Strong'];

        return titles[this.state.score];
    }

    getProgressColor() {
        let classes = ['danger', 'danger', 'warning', 'info', 'success'];
        return classes[this.state.score];
    }

    render() {
        let form_errors = this.state.form_errors;

        return (
            <div>
                <div className="form-group title-label">
                    <label htmlFor="id_new_password1">Create a Password</label>
                    <input type="password" name="new_password1" data-parsley-required="true"
                           data-parsley-required-message="This field is required." className={classNames("form-control", {"is-invalid": form_errors.new_password1 !== undefined})}
                           required="" id="id_new_password1" autoFocus onChange={this.handleChange} />
                    {form_errors.new_password1 !== undefined &&
                        <span className="invalid-feedback">{form_errors.new_password1[0]}</span>
                    }
                </div>

                <div className="form-group title-label">
                    <label htmlFor="id_new_password2">Confirm your Password</label>
                    <input type="password" name="new_password2" data-parsley-required="true"
                           data-parsley-required-message="This field is required."
                           data-parsley-equalto="#id_new_password1"
                           data-parsley-error-message="The two password fields didn't match."
                           className={classNames("form-control", {"is-invalid": form_errors.new_password2 !== undefined})} required="" id="id_new_password2" />
                    {form_errors.new_password2 !== undefined &&
                        <span className="invalid-feedback">{form_errors.new_password2[0]}</span>
                    }
                </div>

                <Progress value={((this.state.score + 1) / 5) * 100} className="mb-1" color={this.getProgressColor()} />
                <small className="text-muted"><i>Password strength: {this.getStrengthTitle()}</i></small>
                <br/><br/>
            </div>
        );
    }
}
