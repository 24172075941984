import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


export default class Fa extends React.Component {
    render() {
        let { size, spacing, icon, spin, className, ...rest} = this.props;

        let sizeStr = null;
        let sizeNum = parseInt(size);
        if(sizeNum === 1) {
            sizeStr = "fa-lg";
        } else if (sizeNum > 1) {
            sizeStr = "fa-" + size + "x";
        }

        let spacingClass = {};
        spacingClass["mr-" + spacing] = spacing;

        return (
              <i className={classNames(
                  "fas",
                  "fa-" + icon,
                  spacingClass,
                  sizeStr,
                  {"fa-spin": spin},
                  className
              )}
              aria-hidden="true"
              {...rest} />
        );
    }
}

Fa.propTypes = {
    icon: PropTypes.string.isRequired,
    spacing: PropTypes.number,
    size: PropTypes.number,
    spin: PropTypes.bool,
    className: PropTypes.string
};

Fa.defaultProps = {
    spacing: null,
    size: 0,
    spin: false,
};
