import React, { Component } from 'react';
import {get} from '../crmapi';
import PlanDetail from './plan_detail';
import ContentWrapper from "../util/contentWrapper";


export default class PlanList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            plans: [],
            loading: true,
        };
    }

    componentDidMount() {
        get('plans/', (response) => {
            this.setState({
                plans: response.data.results,
                loading: false
            });
        });
    }

    render() {
        return (
            <main>
                <ContentWrapper>
                    <a href="/" className="back-link">Back</a>

                    <h1>My Plans</h1>

                    {this.state.loading &&
                        <i className="fas fa-spinner fa-spin"/>
                    }

                    {_.map(this.state.plans, (plan) =>
                        <PlanDetail plan={plan} key={plan.id} />
                    )}
                </ContentWrapper>
            </main>
        );
    }
}
