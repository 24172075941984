import React, {Component} from 'react';
import ContentWrapper from "../util/contentWrapper";
import axios from "axios";
import LoadingWrapper from "../util/loadingWrapper";
import {lineBreaksHtml} from "../util/util";


export default class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            contact_info: {},
        };
    }

    componentDidMount() {
        axios('/api/contact-info/').then((response) => {
            this.setState({
                loading: false,
                contact_info: response.data
            });
        });
    }

    render() {
        return (
            <main>
                <ContentWrapper>
                    <h1  className="mb-5">Contact Us</h1>

                    <LoadingWrapper loading={this.state.loading}>
                        <div className="row">
                            <div className="col-5">
                                <h4 className="mb-0">Phone Number</h4>
                            </div>
                            <div className="col-7">
                                <p className="mb-0">{this.state.contact_info.phone_number}</p>
                            </div>
                        </div>

                        <div className="border-bottom mt-2 mb-2" />

                         <div className="row">
                            <div className="col-5">
                                <h4 className="mb-0">Hours of Operation</h4>
                            </div>
                            <div className="col-7">
                                <p className="mb-0">{lineBreaksHtml(this.state.contact_info.hours)}</p>
                            </div>
                        </div>

                        <div className="border-bottom mt-2 mb-2" />

                        <div className="row">
                            <div className="col-5">
                                <h4 className="mb-0">PO Box</h4>
                            </div>
                            <div className="col-7">
                                <p className="mb-0">{lineBreaksHtml(this.state.contact_info.address)}</p>
                            </div>
                        </div>

                        <div className="border-bottom mt-2 mb-2" />

                        <div className="row">
                            <div className="col-5">
                                <h4 className="mb-0">Email</h4>
                            </div>
                            <div className="col-7">
                                <p className="mb-0">
                                    <a href={`mailto:${this.state.contact_info.email}`}>{this.state.contact_info.email}</a>
                                </p>
                            </div>
                        </div>

                        <div className="border-bottom mt-2 mb-2" />

                        <div className="row">
                            <div className="col-5">
                                <h4 className="mb-0">Chat<br/>(live during set hours): </h4>
                            </div>
                            <div className="col-7">
                                <a id="open_messenger" href="mailto:vlssyn42@intercom-mail.com" className="btn btn-primary btn-small btn-block">Chat Now</a>
                            </div>
                        </div>

                        <div className="border-bottom mt-2 mb-2" />
                    </LoadingWrapper>
                </ContentWrapper>
            </main>
        );
    }
}
