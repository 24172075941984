import axios from 'axios';
import qs from 'qs';

axios.interceptors.response.use(undefined, function (error) {
    if (error.response === undefined) {
        if(error.message !== undefined) {
            setTimeout(() => {
               alert('Could not connect to server: ' + error.message + '\nTry refreshing the page.');
            }, 1000);
        }
    } else if(error.response.status === 401) {
        document.location = '/users/login/?next=' + document.location;
    } else if (error.response.status === 500) {
        var sentry_id = Cookies.get('sentry_id');
        if(sentry_id) {
            Sentry.showReportDialog({eventId: sentry_id});
            Cookies.remove('sentry_id');
        } else {
            setTimeout(() => {
               alert('An error occurred: ' + error.response.statusText);
            }, 100);
        }

    } else {
        return Promise.reject(error);
    }

    return new Promise(() => {});
});

axios.defaults.paramsSerializer = function(params) {
    return qs.stringify(params, { indices: false });
};

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
