import classNames from "classnames";
import React, { Component } from 'react'
import {Form, BasicText, asField, BasicSelect, BasicCheckbox, BasicTextArea} from 'informed';
import {uniqueId} from "lodash";
import PropTypes from "prop-types";
import {ChoiceBasicSelect} from './fields'
import BaseSelect2 from './select2'
import DateInputBasic from "./date_input";
import Fa from "../util/fa";


const BootstrapFieldWrapper = (WrappedComponent) => {
    class BootstrapWrapper extends Component {
        constructor(props) {
            super(props);

            this.state = {id: this.props.id || uniqueId("bootstrap-field-")};
        }

        render() {
            const {fieldState, showLabel, label, helpText, field, required, labelClassName, ...rest} = this.props;

            const newProps = {
                className: classNames("form-control", {"is-invalid": fieldState.error}),
                id: this.state.id,
                fieldState: fieldState,
            };

            return (
                <div className={classNames("form-group", {"required": required, "is-invalid": fieldState.error}, labelClassName)}>
                    {showLabel &&
                        <label htmlFor={this.state.id}>{label || field}</label>
                    }

                    <WrappedComponent {...newProps} {...rest} />

                    {helpText &&
                        <small className="form-text text-muted"><i>{helpText}</i></small>
                    }

                    {(fieldState.error) &&
                        <div className="invalid-feedback">{fieldState.error}</div>
                    }
                </div>
            );
        }
    }

    BootstrapWrapper.defaultProps = {
        showLabel: true,
    };

    BootstrapWrapper.propTypes = {
        showLabel: PropTypes.bool,
        label: PropTypes.string,
        helpText: PropTypes.string,
    };

    return asField(BootstrapWrapper);
};


// const BasicBootstrapCheckbox = ( props ) => {
//     let { className, ...rest } = props;
//     className = (className || "") + " custom-control-input";
//     console.log(props);
//
//     return (
//         <div className="checkbox">
//             <div className="custom-control custom-checkbox">
//                 <BasicCheckbox className={className} {...rest} />
//                 <label className="custom-control-label" htmlFor={props.id}>{props.label}</label>
//             </div>
//         </div>
//     )
// };

export class BaseBootstrapCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {id: this.props.id || uniqueId("bootstrap-field-")};
    }

    render() {
        const {fieldState, showLabel, label, helpText, field, required, labelClassName, ...rest} = this.props;

        const newProps = {
            className: classNames("custom-control-input form-check-input", {"is-invalid": fieldState.error}),
            id: this.state.id,
            fieldState: fieldState,
        };

        return (
            <div className={classNames("form-group", {"required": required, "is-invalid": fieldState.error}, labelClassName)}>
                <div className="checkbox">
                    <div className="custom-control custom-checkbox">
                        <BasicCheckbox {...newProps} {...rest} />
                        <label className={classNames("custom-control-label", labelClassName)} htmlFor={this.state.id}>{label}</label>
                    </div>
                </div>

                {helpText &&
                    <small className="form-text text-muted"><i>{helpText}</i></small>
                }

                {(fieldState.error) &&
                    <div className="invalid-feedback">{fieldState.error}</div>
                }
            </div>
        );
    }
}

class BasicNumber extends Component {
    getValue() {
        const fieldApi = this.props.fieldApi;
        let value = fieldApi.getValue();
        value = parseInt(value);
        if(isNaN(value)) {
            value = 0;
        }
        return value;
    }

    increase() {
        const fieldApi = this.props.fieldApi;
        fieldApi.setValue(this.getValue()+1);
    }

    decrease() {
        const fieldApi = this.props.fieldApi;
        fieldApi.setValue(this.getValue()-1);
    }

    render() {
        return (
            <span className="number-input">
                <BasicText {...this.props} type="number" />
                <span className="number-input-minus" onClick={this.decrease.bind(this)}><Fa icon="minus"/></span>
                <span className="number-input-plus" onClick={this.increase.bind(this)}><Fa icon="plus"/></span>
            </span>
        );
    }
}

export const BootstrapSelect = BootstrapFieldWrapper(BasicSelect);
export const BootstrapText = BootstrapFieldWrapper(BasicText);
export const BootstrapNumber = BootstrapFieldWrapper(BasicNumber);
export const BootstrapTextArea = BootstrapFieldWrapper(BasicTextArea);
export const BootstrapChoiceSelect = BootstrapFieldWrapper(ChoiceBasicSelect);
export const BootstrapCheckbox = asField(BaseBootstrapCheckbox);
export const BootstrapSelect2 = BootstrapFieldWrapper(BaseSelect2);
export const BootstrapDateInput = BootstrapFieldWrapper(DateInputBasic);
