import React from 'react';


export function joinedListLimit(list, limit=2, delimiter=', ') {
    let values = [];

    for(let i in list) {
        let index = parseInt(i);

        if(index < limit - 1 || (index === limit - 1 && list.length === limit)) {
            values.push(<span key={`list-${index}`}>{list[index]}</span>);
        } else {
            let count = list.length - (limit - 1);
            values.push(<strong key={`list-${index}`}>{`${count} other items`}</strong>);
            break;
        }
    }

    if(values.length === 0) {
        return null;
    }

    return <span>{values.reduce((prev, curr) => [prev, delimiter, curr])}</span>;
}


export function get_first_letter(string){
    if(string === null) {
        return '';
    }

    let letters_only = string.replace(/[\W\d_]/g, '');
    if(letters_only.length > 0) {
        return letters_only.charAt(0);
    }
    return '';
}

function isNone(value) {
    return _.isNull(value) || _.isUndefined(value) || _.isNaN(value);
}

export function lineBreaksHtml(value) {
    if(isNone(value)) {
        return "";
    }

    return value.split('\n').map((item, key) => {
        return <span key={key} dangerouslySetInnerHTML={{__html: item + "<br/>"}} />
    });
}
