import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import {uniqueId} from "lodash";
import PropTypes from "prop-types";


export default class HelpLink extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            popoverOpen: false
        };
        this.id = uniqueId("popover-")
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        return (
            <span>
                <button className="help-link btn-link" id={this.id} onClick={this.toggle} type="button">
                    {this.props.linkText}
                </button>

                <Popover placement="bottom" isOpen={this.state.popoverOpen} target={this.id} toggle={this.toggle}>
                    {/*<PopoverHeader>Popover Title</PopoverHeader>*/}
                    <PopoverBody>{this.props.children}</PopoverBody>
                </Popover>
            </span>
        );
    }
}

HelpLink.propTypes = {
    linkText: PropTypes.string.isRequired
};
